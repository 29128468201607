import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Grid";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ExerciseProgressDisplayer = (props) => {
  const MAX_EXERCISES_TO_COMPLETE = props.totalExercises ?? 3;
  const color = props.completed >= MAX_EXERCISES_TO_COMPLETE
    ? "#FCC201"
    : "#EEEEEE";
  const progress = Math.ceil(
    (Math.min(props.completed ?? 0, MAX_EXERCISES_TO_COMPLETE) /
      MAX_EXERCISES_TO_COMPLETE) * 100,
  );

  return (
    <Grid container>
      <Grid
        item
        xs={8}
        sx={{ mt: 2, p: 2 }}
        flexDirection="column"
        justifyContent="center"
      >
        <Typography>
          Exercise progress {props.completed}/{MAX_EXERCISES_TO_COMPLETE}
        </Typography>
      </Grid>
      <Grid xs={4} container justifyContent="flex-end">
        <EmojiEventsIcon sx={{ m: 1, width: 60, height: 60, color: color }} />
      </Grid>
      <Grid item xs={12}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Grid>
    </Grid>
  );
};

export default ExerciseProgressDisplayer;
