import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { AuthContext } from '../../providers/auth-provider';
import { postAssignmentRating } from '../../http-actions/assignment-actions';
import CustomButton from '../button';
import { Trans } from 'react-i18next';

const labels: { [index: string]: string } = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const fiveStarRatingToIntegerRange = (value: number) => Math.floor(value * 2);

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

interface HoverRatingI {
    value: number | null;
    setValue: React.Dispatch<React.SetStateAction<number | null>>;
    onChange: () => void;
}

export const HoverRating = ({ value, setValue, onChange }: HoverRatingI) => {
    const [hover, setHover] = React.useState(-1);

    return (
        <Box
            sx={{
                width: 200,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Rating
                name="hover-feedback-rating"
                size="large"
                value={value}
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(_event, newValue) => {
                    setValue(newValue);
                    onChange();
                }}
                onChangeActive={(_event, newHover) => {
                    setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
        </Box>
    );
};

interface AssignmentRatingDialogI {
    assignmentId: number | undefined;
    open: boolean;
    handleClose: () => void;
}

export const AssignmentRatingDialog = ({
    assignmentId,
    open,
    handleClose,
}: AssignmentRatingDialogI) => {
    const { state: authState } = React.useContext(AuthContext);
    const [value, setValue] = React.useState<number | null>(0);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const handleSubmit = async () => {
        if (!authState?.token || !assignmentId || submitDisabled) return handleClose();

        let quality = value;
        if (!quality) {
            quality = 0;
        }

        const ratings = {
            quality: fiveStarRatingToIntegerRange(quality),
        };

        console.log('RATINGS', ratings);

        const response = await postAssignmentRating(authState.token, assignmentId, ratings);

        if (response?.err) {
            console.log('Could not create rating');
        }

        setValue(0);
        setSubmitDisabled(true);
        handleClose();
    };

    if (!assignmentId) {
        return null;
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle variant="h3" component="h2">
                    Quality rating
                </DialogTitle>
                <DialogContent sx={{ maxWidth: 400 }}>
                    <DialogContentText>
                        <Trans i18nKey="questionRatingExplanation" />
                    </DialogContentText>
                    <Box display="flex" width="100%" pt={2}>
                        <HoverRating
                            value={value}
                            setValue={setValue}
                            onChange={submitDisabled ? () => setSubmitDisabled(false) : () => {}}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" width="100%" justifyContent="space-between">
                        <CustomButton kind="fitech" onClick={handleClose}>
                            <Trans i18nKey="skip" />
                        </CustomButton>
                        <CustomButton
                            kind="fitech"
                            disabled={submitDisabled}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            <Trans i18nKey="submitRating" />
                        </CustomButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssignmentRatingDialog;
