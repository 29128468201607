import {
    Backdrop,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomButton from '../button';
import { useTranslation, Trans } from 'react-i18next';

interface InlineFeedbackDialogPropsI {
    open: boolean | undefined;
    handleClose: () => void;
    parentContainerRef: React.RefObject<HTMLDivElement>;
    initialLeftPosition?: number;
    onSubmit: (data: { feedbackType: string; feedbackText: string }) => void;
}

const positionOffset = 16;
const desiredModalWidth = 400;

const wrapperStyle = (left: number) => ({
    position: 'absolute',
    transform: 'scale(1, 1)',
    transformOrigin: '50% 50%',
    opacity: 1,
    visibility: 'visible',
    left: `${left}px`,
    top: '200px',
});

const formStyle = {
    p: 4,
    maxWidth: `${desiredModalWidth}px`,
    maxHeight: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
};

const feedbackTypes = [
    {
        id: 'hard-to-understand',
        i18nKey: 'hardToUnderstandFeedback',
        explanation: undefined,
    },
    {
        id: 'missing-info',
        i18nKey: 'missingInfoFeedback',
        explanation: undefined,
    },
    {
        id: 'errors',
        i18nKey: 'errorsFeedback',
        explanation: undefined,
    },
    {
        id: 'other-feedback',
        i18nKey: 'otherFeedback',
        explanation: undefined,
    },
];

function InlineFeedbackDialog({
    handleClose,
    open,
    parentContainerRef,
    initialLeftPosition,
    onSubmit,
}: InlineFeedbackDialogPropsI) {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const previousOpenPositionRef = useRef<number>(0);

    const [feedbackType, setFeedbackType] = useState(feedbackTypes[0].id);
    const [feedbackText, setFeedbackText] = useState('');

    const { t } = useTranslation();

    const handleFeedbackTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedbackType((event.target as HTMLInputElement).value);
    };

    const handlefeedbackTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedbackText((event.target as HTMLInputElement).value);
    };

    const getDesiredXPosition = () => {
        if (!parentContainerRef.current) return;
        const parentOffsetX = parentContainerRef.current.getBoundingClientRect().x;
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        const desiredPositionX = Math.max(0, parentOffsetX + positionOffset);
        const screenOverflow = width - desiredPositionX < desiredModalWidth;

        if (screenOverflow) {
            // Hug edge of screen
            const positionAtEdge = Math.max(0, width - desiredModalWidth);
            return positionAtEdge;
        } else {
            // Follow the container edge
            return desiredPositionX;
        }
    };

    const handleModalPosition = useCallback(() => {
        if (!modalContentRef.current) return;
        const positionX = getDesiredXPosition();
        modalContentRef.current.setAttribute('style', `left: ${positionX}px`);
    }, [parentContainerRef.current, modalContentRef.current]);

    const resetFormValues = () => {
        setFeedbackType(feedbackTypes[0].id);
        setFeedbackText('');
    };

    const submitFeedback = () => {
        onSubmit({
            feedbackType,
            feedbackText,
        });
        resetFormValues();
    };

    useEffect(() => {
        if (!window) return;
        window.addEventListener('resize', handleModalPosition);

        return () => {
            window.removeEventListener('resize', handleModalPosition);
        };
    }, [parentContainerRef.current, modalContentRef.current]);

    useEffect(() => {
        previousOpenPositionRef.current = initialLeftPosition || 0;
    }, [initialLeftPosition]);

    return (
        <Modal
            aria-labelledby="feedback-modal-title"
            aria-describedby="feedback-modal-description"
            open={Boolean(open)}
            onClose={handleClose}
            slotProps={{
                backdrop: { sx: { background: 'transparent' } },
            }}
            slots={{ backdrop: Backdrop }}
        >
            <Box
                sx={{
                    '&:focus': {
                        outline: 'none',
                    },
                    ...wrapperStyle(initialLeftPosition || previousOpenPositionRef.current),
                }}
                ref={modalContentRef}
            >
                <Paper component="form" onSubmit={submitFeedback} sx={formStyle}>
                    <Typography id="feedback-modal-title" variant="h5" component="h2">
                        <Trans i18nKey="feedbackOnSelection" />
                    </Typography>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="demo-radio-buttons-group-label">
                            <Trans i18nKey="whatKindFeedback" />
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={feedbackType}
                            onChange={handleFeedbackTypeChange}
                            name="radio-buttons-group"
                        >
                            {feedbackTypes.map((type, index) => {
                                return (
                                    <FormControlLabel
                                        key={`${index}-${type.id}`}
                                        value={type.id}
                                        control={<Radio />}
                                        label={t(type.i18nKey)}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    <Typography
                        id="feedback-modal-other-title"
                        variant="h6"
                        component="h2"
                        sx={{ mt: 2 }}
                    >
                        <Trans i18nKey="shareOpenFeedback" />
                    </Typography>
                    <TextField
                        id="feedback-other-helperText"
                        value={feedbackText}
                        onChange={handlefeedbackTextChange}
                        helperText={t('notSharePersonal')}
                        variant="standard"
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        maxRows={5}
                    />
                    <Typography variant="caption" id="feedback-modal-description" sx={{ mt: 2 }}>
                        <Trans i18nKey="feedbackCollectionPurpose" />
                    </Typography>
                    <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <CustomButton kind="fitech" onClick={handleClose}>
                            <Trans i18nKey="cancel" />
                        </CustomButton>
                        <CustomButton onClick={submitFeedback} kind="fitech" variant="contained">
                            <Trans i18nKey="submit" />
                        </CustomButton>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}

export default InlineFeedbackDialog;
