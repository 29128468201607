export const sortPartKeys = (partKeys) =>
    partKeys.sort((part1, part2) => {
        part1 = part1.replace(/\/$/, '');
        part2 = part2.replace(/\/$/, '');

        const p1s = part1.split('/');
        const p2s = part2.split('/');
        const p1Index = p1s[p1s.length - 1].split('-')[0];
        const p2Index = p2s[p2s.length - 1].split('-')[0];

        return Number(p1Index) - Number(p2Index);
    });

export const getNextModuleFirstPart = (currentNode, moduleMap) => {
    const mod = moduleMap[currentNode.fields.category];
    const nextModule = Object.values(moduleMap).find((entry) => entry.order === mod.order + 1);

    if (!nextModule || !nextModule.published) {
        return;
    }
    let partKeys = Object.keys(nextModule?.parts);
    try {
        partKeys = sortPartKeys(partKeys);
    } catch (e) {
        // epic fail but we do not care
    }
    return nextModule.parts[partKeys[0]];
};

export const getPreviousModuleFirstPart = (currentNode, moduleMap) => {
    const mod = moduleMap[currentNode.fields.category];
    const prevModule = Object.values(moduleMap).find((entry) => entry.order === mod.order - 1);

    if (!prevModule || !prevModule.published) {
        return;
    }
    let partKeys = Object.keys(prevModule?.parts);
    try {
        partKeys = sortPartKeys(partKeys);
    } catch (e) {
        // epic fail but we do not care
    }
    return prevModule.parts[partKeys[partKeys.length - 1]];
};

export const getPrevAndNextPart = (currentNode, moduleMap, pageContext) => {
    const mod = moduleMap[currentNode.fields.category];
    if (!mod?.published) {
        return [undefined, undefined];
    }
    let partKeys = Object.keys(mod.parts);
    try {
        partKeys = sortPartKeys(partKeys);
    } catch (e) {
        // epic fail but we do not care
    }

    const currentIndex = partKeys?.indexOf(currentNode.fields.slug);

    if (partKeys?.length <= 1) {
        return [undefined, undefined];
    } else if (currentIndex === partKeys?.length - 1) {
        // We don't want to go to other courses without transparency
        if (pageContext?.sidebar?.hideOtherModules) {
            return [mod.parts[partKeys[currentIndex - 1]], undefined];
        }
        // We go to the next module
        return [
            mod.parts[partKeys[currentIndex - 1]],
            getNextModuleFirstPart(currentNode, moduleMap),
        ];
    } else if (currentIndex === 0) {
        // We don't want to go to other courses without transparency
        if (pageContext?.sidebar?.hideOtherModules) {
            return [undefined, mod.parts[partKeys[currentIndex + 1]]];
        }
        // We go to the next module
        return [
            getPreviousModuleFirstPart(currentNode, moduleMap),
            mod.parts[partKeys[currentIndex + 1]],
        ];
    } else {
        return [mod.parts[partKeys[currentIndex - 1]], mod.parts[partKeys[currentIndex + 1]]];
    }
};
