/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";

import { Trans, useTranslation } from "react-i18next";

const Label = (props) => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        fontSize: "1rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
    >
      {props.children}
    </Typography>
  );
};

const sxSliderStyle = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  },
};

const sxSliderStyleExp = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="4"]': {
      transform: "translateX(-100%)",
    },
  },
};

const PostAllExerciseSurveyForm = (props) => {
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [data, setData] = React.useState({
    usefulForMyLearning: 0,
    engagingToMe: 0,
    selectionWasSatisfactory: 0,
    enjoyedBeingAbleToSelectThemes: 0,
    openFeedback: null,
  });

  const handleChange = (key, newValue: number | number[]) => {
    const updated = {
      ...data,
      [key]: newValue,
    };

    setData(updated);
    props.updateData(updated);
  };

  const onTextChange = (event) => {
    const updated = {
      ...data,
      openFeedback: event.target.value,
    };
    setData(updated);
    if (timeoutId) {
      try {
        clearTimeout(timeoutId);
      } catch (e) {
        // console.log(e);
      }
    }
    const tId = setTimeout(() => {
      props.updateData(updated);
    }, 500);
    setTimeoutId(tId);
  };

  const likertScaleAgreement = [
    {
      value: 1,
      label: "Strongly disagree",
    },
    {
      value: 7,
      label: "Strongly agree",
    },
  ];

  const valueText = (value) => {
    return `${value}`;
  };

  return (
    <>
      <Typography
        gutterBottom
        sx={{ pt: 2 }}
      >
        Congrats on successfully completing the exercises! Please provide us
        overall feedback!
      </Typography>
      <Label component="legend">
        Please indicate how much you agree with each of the following
        statements, or how true it is about you. Use the scale from 1 to 7,
        where 1 is 'Strongly disagree' and 7 is 'Strongly agree'.
      </Label>
      <Typography gutterBottom id="usefulForMyLearning" sx={{ pt: 2 }}>
        The exercises were useful for my learning.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="usefulForMyLearning"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) => handleChange("usefulForMyLearning", v)}
      />
      <Typography gutterBottom id="engagingToMe" sx={{ pt: 2 }}>
        The exercises were engaging to me.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="engagingToMe"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) => handleChange("engagingToMe", v)}
      />
      <Typography
        gutterBottom
        id="selectionWasSatisfactory"
        sx={{ pt: 2 }}
      >
        The theme selection was satisfactory to me.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="selectionWasSatisfactory"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) =>
          handleChange("selectionWasSatisfactory", v)}
      />
      <Typography
        gutterBottom
        id="enjoyedBeingAbleToSelectThemes"
        sx={{ pt: 2 }}
      >
        I enjoyed being able to select themes that match my interests.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="enjoyedBeingAbleToSelectThemes"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) =>
          handleChange("enjoyedBeingAbleToSelectThemes", v)}
      />
      <Typography
        gutterBottom
        id="openFeedback"
        sx={{ pt: 2 }}
      >
        Please provide open feedback on the AI-generated practice exercises.
      </Typography>
      <TextField
        id="outlined-multiline-static"
        label={"Open feedback on AI-generated practice exercises."}
        multiline
        rows={4}
        sx={{ width: "100%" }}
        value={data.openFeedback ?? ""}
        onChange={onTextChange}
      />
    </>
  );
};

const PostAllExercisesSurvey = (props) => {
  const [data, setData] = React.useState({
    usefulForMyLearning: 0,
    engagingToMe: 0,
    selectionWasSatisfactory: 0,
    enjoyedBeingAbleToSelectThemes: 0,
    openFeedback: null,
  });

  const updateData = (data) => {
    setData(data);
  };

  const skip = () => {
    props.submitFeedback({
      ...data,
      selection: "skip",
      type: "post-all-exercises-survey",
    });
  };

  const submitFeedback = () => {
    props.submitFeedback({
      ...data,
      selection: "submit",
      type: "post-all-exercises-survey",
    });
  };

  return (
    <>
      <PostAllExerciseSurveyForm
        updateData={updateData}
      />
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={submitFeedback}
        >
          Submit feedback
        </Button>
        <Button
          variant="outlined"
          onClick={skip}
          sx={{ ml: 2 }}
        >
          Skip
        </Button>
      </Box>
    </>
  );
};

export default PostAllExercisesSurvey;
