import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import DartpadAssignment from "../programming/code-area/dartpad-assignment.tsx";
import ExerciseProgressDisplayer from "./exercise-progress-displayer.tsx";
import ThemeSelection from "./theme-selection.tsx";
import MarkdownPreview from "../../markdown-preview.tsx";
import { AuthContext } from "../../../providers/auth-provider.tsx";
import * as exerciseActions from "../../../http-actions/ai-generated-exercise-actions.tsx";
import PostExerciseSurvey from "./surveys/post-exercise-survey.tsx";
import PostAllExercisesSurvey from "./surveys/post-all-exercises-survey.tsx";

const EXERCISES_TO_COMPLETE = 3;

const hasHtml = (str) => {
  return /<[a-z][\s\S]*>/i.test(str);
};

const ThemedAssignmentPool = (props) => {
  const scrollToRef = useRef(null);

  const chapterId = props.chapterId ?? "1";
  const { state: authState } = useContext(AuthContext);

  const [selections, setSelections] = useState({});
  const [assignment, setAssignment] = useState(null);
  const [options, setOptions] = React.useState({});
  const [error, setError] = useState(null);
  const [completedExercises, setCompletedExercises] = useState(0);
  const [gradingInProgress, setGradingInProgress] = useState(false);
  const [currentExercisePassed, setCurrentExercisePassed] = useState(false);

  const [gradingFeedbackIsOpen, setGradingFeedbackIsOpen] = useState(false);
  const [gradingFeedback, setGradingFeedback] = useState(null);
  const [postExerciseSurveyIsOpen, setPostExerciseSurveyIsOpen] = useState(
    false,
  );
  const [noExercisesForSelection, setNoExercisesForSelection] = useState(
    false,
  );

  useEffect(() => {
    if (!authState?.token) {
      return;
    }
    getExerciseChapterData();
  }, [authState?.token]);

  const postExerciseSurveyFeedback = async (feedback) => {
    feedback.chapter = chapterId;
    feedback.exercise = assignment.id;
    feedback.completedExercises = completedExercises;

    await exerciseActions.postFeedback(authState.token, feedback);
    setPostExerciseSurveyIsOpen(false);
  };

  const getExerciseChapterData = async () => {
    const data = await exerciseActions.getRemedialExerciseChapterData(
      authState?.token,
      chapterId,
    );

    setOptions({
      themes: data.themes,
      concepts: data.concepts,
      difficulty: data.difficulties,
    });

    setCompletedExercises(data.completedExercises ?? 0);
  };

  if (Object.keys(options).length === 0) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h2">
            Loading exercises...
          </Typography>
        </Paper>
      </Box>
    );
  }

  const submitAssignment = async (dataToSubmit) => {
    setGradingInProgress(true);
    console.log("Submit code to server");
    console.log(dataToSubmit);
    const result = await exerciseActions.submitExerciseForGrading(
      authState.token,
      chapterId,
      assignment.id,
      dataToSubmit,
    );
    console.log("Got result:");
    if (result.status === "passed") {
      console.log("Exercise passed");
      setCompletedExercises(completedExercises + 1);
      setCurrentExercisePassed(true);
      setPostExerciseSurveyIsOpen(true);
      try {
        if (window && window.scrollTo) {
          window.scrollTo({
            top: scrollToRef.current.offsetTop,
            behavior: "smooth",
          });
        }
      } catch (e) {
      }
    } else {
      console.log("Exercise did not pass");
      setCurrentExercisePassed(false);
      setGradingFeedback(result);
      setGradingFeedbackIsOpen(true);
    }

    setGradingInProgress(false);
  };

  const actionButtons = (onSubmit) => {
    if (currentExercisePassed) {
      return (
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            m: 2,
            p: 2,
          }}
        >
          <Typography variant="h3">
            🎉 Exercise passed, congratulations! 🎉
          </Typography>

          <Typography>
            {completedExercises < EXERCISES_TO_COMPLETE
              ? "Scroll up and select a new exercise."
              : "Great work with the practice, move on!"}
          </Typography>
        </Box>
      );
    }

    return (
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={onSubmit}
        fullWidth
        sx={{ mt: 2 }}
        disabled={gradingInProgress}
      >
        {gradingInProgress
          ? (
            <>
              <CircularProgress sx={{ mr: 2 }} size={20} />{" "}
              Code is being graded, wait...
            </>
          )
          : "Submit for grading"}
      </Button>
    );
  };

  const changeSelection = (key, newTheme) => {
    setSelections({ ...selections, [key]: newTheme });
    setNoExercisesForSelection(false);
  };

  const fetchExercise = async () => {
    setAssignment(null);
    setCurrentExercisePassed(false);
    if (!selections.theme || !selections.concept || !selections.difficulty) {
      setError("Please select a theme, a concept, and a difficulty.");
      return;
    }
    setError(null);

    console.log("Fetching exercise with selections:");
    console.log(selections);
    const exercise = await exerciseActions.getExerciseWithSelections(
      authState.token,
      chapterId,
      selections,
    );

    console.log("Found:");
    console.log(exercise);
    if (exercise?.available === 0 || exercise?.error) {
      setNoExercisesForSelection(true);
      return;
    }

    exercise.type = "dart3";
    exercise.assignmentId = `aigen_${exercise.id}`;

    setAssignment(exercise);
  };

  const assignmentAreaContent = assignment &&
    !currentExercisePassed && (
    <>
      <Typography variant="h4" sx={{ p: 1, mt: 2 }}>
        Note! The sample outputs in the exercises also include the input
        provided by the user. In the exercises, you are not expected to print
        the input directly.
      </Typography>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h3">
          Exercise: {assignment.title}
        </Typography>
        {assignment.problemDescription &&
          !hasHtml(assignment.problemDescription) && (
          <MarkdownPreview>
            {assignment.problemDescription}
          </MarkdownPreview>
        )}
        {assignment.problemDescription &&
          hasHtml(assignment.problemDescription) && (
          <Box
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{
              __html: assignment.problemDescription.replaceAll("\\n", "\n")
                .replaceAll("\n\n", "\n"),
            }}
          />
        )}
        <DartpadAssignment
          iFrameId={`dartpad-themed-assignment-iframe-${assignment.id}`}
          assignment={assignment}
          getActionButtons={actionButtons}
          submitAssignment={submitAssignment}
          nullSafe
        />
        <ExerciseProgressDisplayer
          completed={completedExercises}
          totalExercises={EXERCISES_TO_COMPLETE}
        />
      </Paper>
    </>
  );

  const noAssignmentsAvailable = noExercisesForSelection &&
    !currentExercisePassed && (
    <>
      <Typography variant="h4" sx={{ p: 1, mt: 2 }}>
        Note! The sample outputs in the exercises also include the input
        provided by the user. In the exercises, you are not expected to print
        the input directly.
      </Typography>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h3">
          😭 Out of exercises! 😭
        </Typography>
        <Typography sx={{ mt: 2 }}>
          No more exercises available with the given selection. <br />
          Please modify your selections for theme, concept, or difficulty (or
          all of them).
        </Typography>
      </Paper>
    </>
  );

  const postAllExercisesSurvey = currentExercisePassed &&
    completedExercises >= EXERCISES_TO_COMPLETE && (
    <Dialog
      open={postExerciseSurveyIsOpen}
      onClose={() => null}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="post-exercise-survey-dialog-title"
      aria-describedby="post-exercise-survey"
    >
      <DialogTitle id="post-exercise-survey">
        🎉 All exercises passed, congratulations! 🎉
      </DialogTitle>
      <DialogContent>
        <PostAllExercisesSurvey submitFeedback={postExerciseSurveyFeedback} />
      </DialogContent>
    </Dialog>
  );

  const postExerciseSurvey = currentExercisePassed &&
    completedExercises < EXERCISES_TO_COMPLETE &&
    (
      <Dialog
        open={postExerciseSurveyIsOpen}
        onClose={() => null}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="post-exercise-survey-dialog-title"
        aria-describedby="post-exercise-survey"
      >
        <DialogTitle id="post-exercise-survey">
          🎉 Exercise passed, congratulations! 🎉
        </DialogTitle>
        <DialogContent>
          <PostExerciseSurvey submitFeedback={postExerciseSurveyFeedback} />
        </DialogContent>
      </Dialog>
    );

  const graderFeedback = gradingFeedback !== null && gradingFeedbackIsOpen && (
    <Dialog
      open={gradingFeedbackIsOpen && gradingFeedback !== null}
      onClose={() => null}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="grader-feedback-dialog-title"
      aria-describedby="grader-feedback-description"
    >
      <DialogTitle id="grader-feedback-dialog-title">
        The submission passed {gradingFeedback?.testsPassed} /{" "}
        {gradingFeedback?.testsPassed + gradingFeedback?.testsFailed}{"  "}
        tests. Here's feedback from one failing test.
      </DialogTitle>
      <DialogContent>
        {gradingFeedback?.tests?.filter((test) => test.passed === false)
          ?.splice(0, 1)?.map((test) => {
            return (
              <>
                <DialogContentText id="grader-feedback-description">
                  Test name: {test.testName}
                </DialogContentText>
                <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
                  {test["test output"]}
                </DialogContentText>
              </>
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setGradingFeedbackIsOpen(false)}>
          Close feedback
        </Button>
      </DialogActions>
    </Dialog>
  );

  const exerciseButtons = (
    <>
      <Button
        variant="contained"
        size="large"
        sx={{ mt: 2 }}
        onClick={fetchExercise}
        // disabled={assignment != null && currentExercisePassed === false}
      >
        {completedExercises == 0 ? "Get exercise" : "Get new exercise"}
      </Button>
      {
        /* <Button
        variant="outlined"
        size="large"
        sx={{ mt: 2, ml: 2 }}
        onClick={fetchExercise}
        disabled={assignment == null || currentExercisePassed === true}
      >
        Skip this exercise
      </Button> */
      }
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper sx={{ p: 2 }}>
        {
          /* <Button
          onClick={() => exerciseActions.gradeAllExercises(authState?.token)}
          variant="contained"
          color="secondary"
        >
          Do not press this button -- it's here to allow running all exercises
          easily :D
        </Button> */
        }
        <Typography variant="h2" ref={scrollToRef}>
          AI-generated Practice Exercises
        </Typography>
        <ExerciseProgressDisplayer
          completed={completedExercises}
          totalExercises={EXERCISES_TO_COMPLETE}
        />
        <Typography sx={{ mt: 2, mb: 2 }}>
          Here, you can practice the contents of this part using AI-generated
          practice exercises. To receive a new exercise, select a theme, a
          concept, and the difficulty and then press the button below. Once
          loaded, the new exercise will be displayed below.
        </Typography>
        <ThemeSelection changeSelection={changeSelection} options={options} />
        {error && (
          <Typography color="error">
            {error}
          </Typography>
        )}
        {exerciseButtons}
      </Paper>
      {postExerciseSurvey}
      {postAllExercisesSurvey}
      {assignmentAreaContent}
      {noAssignmentsAvailable}
      {graderFeedback}
    </Box>
  );
};

export default ThemedAssignmentPool;
