import { Box } from '@mui/material';
import React from 'react';
import RandomizedExperiment from '../experiments/randomized-experiments/randomized-experiment';
import RandomizedExperimentGroup from '../experiments/randomized-experiments/randomized-experiment-group';
import InlineFeedbackTrack from './inline-feedback-track';

interface InlineFeedbackContainerPropsI {
    disabled?: boolean;
    children: null | React.ReactElement<any, any>;
}

function InlineFeedbackContainer({ disabled, children }: InlineFeedbackContainerPropsI) {
    if (disabled) return children;

    return (
        <>
            <Box className="feedback-wrapper" position="relative">
                {children}
                <RandomizedExperiment name="2022-inline-feedback-v1">
                    <RandomizedExperimentGroup
                        id="inline-feedback-v1-control"
                        reactType="RandomizedExperimentGroup"
                    >
                        <></>
                    </RandomizedExperimentGroup>
                    <RandomizedExperimentGroup
                        id="inline-feedback-v1-enabled"
                        reactType="RandomizedExperimentGroup"
                    >
                        <InlineFeedbackTrack />
                    </RandomizedExperimentGroup>
                    <RandomizedExperimentGroup
                        id="inline-feedback-v1-enabled-and-hinted-with-prob-1"
                        reactType="RandomizedExperimentGroup"
                    >
                        <InlineFeedbackTrack showHint hintProbability={1} />
                    </RandomizedExperimentGroup>
                </RandomizedExperiment>
            </Box>
        </>
    );
}

export default InlineFeedbackContainer;
