import React from "react";
import { Container } from "@mui/material";

const NewApiEval = (props) => {
  const poolUuid = "b6a36f21-964e-4ce0-b735-5801ba9e2ed8";
  const shouldHaveAccessExercise = "fbdabc3c-10e2-47bd-855e-d1089c3b03fe";
  const shouldNotHaveAccessExercise = "17b9c9f9-0dd6-4f1f-a3b1-8de037e76f2d";

  let localStorage = null;
  if (typeof window != "undefined" && window && window.localStorage) {
    localStorage = window.localStorage; 
  }

  const [token, setToken] = React.useState(
    localStorage?.getItem("token"),
  );

  const [points, setPoints] = React.useState({});
  const [starter, setStarter] = React.useState({});
  const [unAllowedStarter, setUnAllowedStarter] = React.useState({});
  const [code, setCode] = React.useState("void main() {\n}\n");
  const [solveRequestResponse, setSolveRequestResponse] = React.useState({});
  const [submissionStatus, setSubmissionStatus] = React.useState({});
  const [solveQuizRequestResponse, setSolveQuizRequestResponse] = React
    .useState({});
  const [quizSubmissionStatus, setQuizSubmissionStatus] = React.useState({});

  const updateToken = () => {
    setToken(localStorage?.getItem("token"));
  };

  const importExercises = async () => {
    await fetch("http://localhost:8842/api/imports/refresh-exercises", {
      method: "GET",
      headers: { "Authorization": token },
    });
  };

  const getPoints = async () => {
    const pointsRequest = await fetch(
      `http://localhost:8842/api/points/pools/${poolUuid}`,
      {
        method: "GET",
        headers: { "Authorization": token },
      },
    );

    setPoints(await pointsRequest.json());
  };

  const loadExercises = async () => {
    console.log("Doing stuff...");

    const okStarterRequest = await fetch(
      `http://localhost:8842/api/exercises/${shouldHaveAccessExercise}/pools/${poolUuid}/starter`,
      {
        method: "GET",
        headers: { "Authorization": token },
      },
    );

    setStarter(await okStarterRequest.json());

    const notOkStarterRequest = await fetch(
      `http://localhost:8842/api/exercises/${shouldNotHaveAccessExercise}/pools/${poolUuid}/starter`,
      {
        method: "GET",
        headers: { "Authorization": token },
      },
    );

    setUnAllowedStarter(await notOkStarterRequest.json());
  };

  const solveExercise = async () => {
    console.log("Doing stuff...");
    const data = {
      "main.dart": code,
    };

    const payload = {
      data: data,
      exerciseUuid: shouldHaveAccessExercise,
      poolUuid: poolUuid,
    };

    const solveRequest = await fetch(`http://localhost:8842/api/submissions`, {
      method: "POST",
      headers: { "Authorization": token },
      body: JSON.stringify(payload),
    });

    const resData = await solveRequest.json();
    setSolveRequestResponse(resData);
  };

  const getSubmissionStatus = async () => {
    const submissionStatusRes = await fetch(
      `http://localhost:8842/api/submissions/status/${solveRequestResponse.submissionUuid}`,
      {
        method: "GET",
        headers: { "Authorization": token },
      },
    );

    const submissionStatusJson = await submissionStatusRes.json();
    setSubmissionStatus(submissionStatusJson);
  };

  const solveQuizCorrectly = async () => {
    console.log("Doing stuff...");
    const data = ["Tästä se alkaa!"];

    const payload = {
      data: data,
      exerciseUuid: "8f32ca5f-dfb7-4e4d-ac91-3bdb8bff364a",
      poolUuid: "9aee2962-8719-46ff-8859-dbf855e10e23",
    };

    const solveRequest = await fetch(`http://localhost:8842/api/submissions`, {
      method: "POST",
      headers: { "Authorization": token },
      body: JSON.stringify(payload),
    });

    const resData = await solveRequest.json();
    setSolveQuizRequestResponse(resData);
  };

  const solveQuizIncorrectly = async () => {
    console.log("Doing stuff...");
    // const data = ["'Tästä se alkaa!'"];
    // const data = ["a", "b"];
    const data = ["b", "a"];

    const payload = {
      data: data,
      exerciseUuid: "8f32ca5f-dfb7-4e4d-ac91-3bdb8bff364a",
      poolUuid: "9aee2962-8719-46ff-8859-dbf855e10e23",
    };

    const solveRequest = await fetch(`http://localhost:8842/api/submissions`, {
      method: "POST",
      headers: { "Authorization": token },
      body: JSON.stringify(payload),
    });

    const resData = await solveRequest.json();
    setSolveQuizRequestResponse(resData);
  };

  const getQuizSubmissionStatus = async () => {
    const submissionStatusRes = await fetch(
      `http://localhost:8842/api/submissions/status/${solveQuizRequestResponse.submissionUuid}`,
      {
        method: "GET",
        headers: { "Authorization": token },
      },
    );

    const submissionStatusJson = await submissionStatusRes.json();
    setQuizSubmissionStatus(submissionStatusJson);
  };

  return (
    <Container sx={{ backgroundColor: "lightgray", p: 1 }}>
      <strong>Token: {token}</strong>
      <br />
      <button onClick={updateToken}>Update token from localstorage!</button>
      <hr />

      {token && (
        <>
          <button onClick={importExercises}>Import exercises!</button>

          <hr />
          <button onClick={getPoints}>Get points!</button>

          <p>
            {JSON.stringify(points)}
          </p>
          <hr />
          <button onClick={loadExercises}>Load exercises!</button>
          <br />

          <pre>
        Starter:
          </pre>
          <pre>
        {JSON.stringify(starter)}
          </pre>
          <pre>
        Unallowed starter:
          </pre>
          <pre>
        {JSON.stringify(unAllowedStarter)}
          </pre>

          <hr />

          <textarea
            onChange={(e) => setCode(e.target.value)}
            rows={8}
            cols={60}
          >
            {code}
          </textarea>
          <br />
          <button onClick={solveExercise}>Solve (i.e. submit)!</button>

          <pre>
        Solve request response:
          </pre>
          <p>
            {JSON.stringify(solveRequestResponse)}
          </p>
          <button onClick={getSubmissionStatus}>Get submission status!</button>
          <pre>
        Submission status:
          </pre>
          <p>
            {JSON.stringify(submissionStatus)}
          </p>
          <hr />

          <button onClick={solveQuizCorrectly}>Solve quiz correctly!</button>
          <button onClick={solveQuizIncorrectly}>
            Solve quiz incorrectly!
          </button>

          <pre>
        Solve quiz request response:
          </pre>
          <p>
            {JSON.stringify(solveQuizRequestResponse)}
          </p>
          <button onClick={getQuizSubmissionStatus}>
            Get submission status!
          </button>
          <pre>
        Quiz submission status:
          </pre>
          <p>
            {JSON.stringify(quizSubmissionStatus)}
          </p>
          <hr />
        </>
      )}
    </Container>
  );
};

export default NewApiEval;
