import { Fab } from '@mui/material';
import { Feedback as FeedbackIcon } from '@mui/icons-material';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import InlineFeedbackDialog from './inline-feedback-dialog';
import { AuthContext } from '../../providers/auth-provider';
import { postInlineFeedback } from '../../http-actions/feedback-actions';
import { enqueueNotification as enqueueNotificationAction } from '../../reducers/notifications-slice';
import store from '../../store';
import { useTranslation } from 'react-i18next';

interface InlineFeedbackButtonPropsI {
    visible?: boolean;
    onTrack?: boolean;
    parentContainerRef: React.RefObject<HTMLDivElement>;
    handleHideButton: () => void;
}

const dispatch = store.dispatch;
const enqueueNotification = (payload: any) => dispatch(enqueueNotificationAction(payload));

const FAB_HEIGHT = 48;

const positionOffset = 16;
const desiredModalWidth = 400;

const highlightClassName = 'feedback-select-highlight';
const highlightableClasses: string[] = ['gatsby-highlight', 'feedback-target'];
const highlightableTags = ['p', 'li', 'table', 'figure'];

function InlineFeedbackButton({
    visible,
    parentContainerRef,
    handleHideButton,
    onTrack,
}: InlineFeedbackButtonPropsI) {
    const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "unknown";

    if (pathname.includes("experiments") && pathname.includes("code-recall")) {
        return null;
    }

    const inlineButtonRef = useRef<HTMLButtonElement>(null);
    const [initialFeedbackLeftPosition, setInitialFeedbackLeftPosition] = useState<
        number | undefined
    >();
    const selectedElement = useRef<Element>();
    const highlightedElementRef = useRef<Element>();

    const { state: authState } = useContext(AuthContext);

    const timerRef = useRef<undefined | NodeJS.Timeout>();

    const { t } = useTranslation();

    const debounce = (callback: () => void, ms: number) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(callback, ms);
    };

    const feedbackOpen = Boolean(initialFeedbackLeftPosition);

    const handleButtonPosition = useCallback(
        (event: MouseEvent) => {
            if (!inlineButtonRef.current || !parentContainerRef.current || !window) return;

            const parentRect = parentContainerRef.current.getBoundingClientRect();
            const parentOffsetX = parentRect.x + window.scrollX;
            const parentOffsetY = parentRect.y + window.scrollY;

            const mouseOffsetX = event.clientX + window.scrollX;
            const mouseOffsetY = event.clientY + window.scrollY;

            if (onTrack) {
                const newButtonOffset = Math.max(mouseOffsetY - parentOffsetY - FAB_HEIGHT / 2, 0);
                inlineButtonRef.current.setAttribute(
                    'style',
                    `transform: translateX(44px) translateY(${newButtonOffset}px)`,
                );
            } else {
                const newButtonXOffset = Math.max(
                    mouseOffsetX - parentOffsetX - FAB_HEIGHT / 1.2,
                    0,
                );
                const newButtonYOffset = Math.max(
                    mouseOffsetY - parentOffsetY - FAB_HEIGHT / 1.2,
                    0,
                );
                inlineButtonRef.current.setAttribute(
                    'style',
                    `transform: translateX(${newButtonXOffset}px) translateY(${newButtonYOffset}px)`,
                );
            }

            const withinX =
                parentRect.left + window.scrollX <= mouseOffsetX &&
                parentRect.right + window.scrollX >= mouseOffsetX;

            const withinY =
                parentRect.top + window.scrollY <= mouseOffsetX &&
                parentRect.bottom + window.scrollY >= mouseOffsetX;

            if (withinX && withinY) {
                debounce(createHandleElementHighlightCallback(event), 5);
            }
        },
        [parentContainerRef.current, inlineButtonRef.current, onTrack],
    );

    const createHandleElementHighlightCallback = (event: MouseEvent) => () => {
        handleElementHighlight(event);
    };

    const hasValidClass = (element: HTMLElement | Element | undefined | null) => {
        return highlightableClasses.some((className) => !!element?.classList.contains(className));
    };

    const hasValidTag = (element: HTMLElement | Element | undefined | null) => {
        return highlightableTags.some((tag) => tag === element?.tagName.toLowerCase());
    };

    const findValidElement = (element: Element) => {
        let currentParent: HTMLElement | Element | undefined | null = element;

        if (hasValidClass(currentParent) || hasValidTag(currentParent)) return element;

        for (let i = 0; i < 4; i++) {
            if (hasValidClass(currentParent?.parentElement)) return currentParent?.parentElement;

            if (hasValidTag(currentParent?.parentElement)) return currentParent?.parentElement;

            currentParent = currentParent?.parentElement;
            if (currentParent?.tagName.toLowerCase() === 'main') {
                return;
            }
        }
    };

    const handleElementHighlight = (event: MouseEvent) => {
        if (!parentContainerRef.current || !window) return;
        const parentRect = parentContainerRef.current.getBoundingClientRect();
        const parentOffsetX = parentRect.x;
        const mouseOffsetY = event.clientY;

        const leftSelectionOffset = 250;
        const rightSelectionOffset = 300;

        const parentX = parentContainerRef.current.parentElement?.getBoundingClientRect().x;

        const xCoord =
            typeof parentX !== 'undefined'
                ? parentX + leftSelectionOffset
                : parentOffsetX - rightSelectionOffset;
        const yCoord = mouseOffsetY;

        const elementUnderCoord = document.elementFromPoint(xCoord, yCoord);

        if (elementUnderCoord) {
            const elementToHighlight = findValidElement(elementUnderCoord);
            if (!elementToHighlight) return;

            if (elementToHighlight.classList.contains(highlightClassName)) return;

            // Check if different element
            if (elementToHighlight.innerHTML !== highlightedElementRef.current?.innerHTML) {
                removeCurrentHighlightedElement();
            }

            // Highlight
            elementToHighlight.classList.add(highlightClassName);
            highlightedElementRef.current = elementToHighlight;
        }
    };

    const getDesiredXPosition = () => {
        if (!parentContainerRef.current) return;
        const parentOffsetX = parentContainerRef.current.getBoundingClientRect().x;
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        const desiredPositionX = Math.max(0, parentOffsetX + positionOffset);
        const screenOverflow = width - desiredPositionX < desiredModalWidth;

        if (screenOverflow) {
            // Hug edge of screen
            const positionAtEdge = Math.max(0, width - desiredModalWidth);
            return positionAtEdge;
        } else {
            // Follow the container edge
            return desiredPositionX;
        }
    };

    useEffect(() => {
        if (!parentContainerRef.current) return;
        parentContainerRef.current.addEventListener('mousemove', handleButtonPosition);

        return () => {
            parentContainerRef?.current?.removeEventListener('mousemove', handleButtonPosition);
        };
    }, [parentContainerRef.current]);

    useEffect(() => {
        if (!visible && !feedbackOpen) {
            removeCurrentHighlightedElement();
        }
    }, [visible]);

    const removeCurrentHighlightedElement = () => {
        const elementWithClassToRemove = highlightedElementRef.current;
        const removalDelay = 10;
        setTimeout(() => {
            elementWithClassToRemove?.classList.remove(highlightClassName);
        }, removalDelay);
    };

    const setHighlightedElementAsSelected = () => {
        selectedElement.current = highlightedElementRef.current;
    };

    const removeHighlightedElementAsSelected = () => {
        selectedElement.current = undefined;
    };

    const handleButtonClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
        const parentOffsetX = getDesiredXPosition();
        setInitialFeedbackLeftPosition(parentOffsetX);
        setHighlightedElementAsSelected();
        handleHideButton();
    };

    const handleCloseFeedback = () => {
        handleHideButton();
        removeCurrentHighlightedElement();
        setInitialFeedbackLeftPosition(undefined);
    };

    const parseLocation = (location: Location) => {
        let path = location.pathname.replace(/\/+$/, '');
        path = path[0] === '/' ? path.substring(1) : path;
        const pathArray = path.split('/');
        const loc = pathArray[0] + '-' + pathArray[1] + "-" + pathArray[2];
        return loc
    }

    const onDialogSubmit = async (data: { feedbackType: string; feedbackText: string }) => {
        if (!authState?.token) return;

        const selectedInnerHTML = selectedElement.current?.innerHTML;
        const selectedInnerHTML512 = selectedInnerHTML?.substring(0, 512)

        const selectedTextContent = selectedElement.current?.textContent;

        const selectedRect = selectedElement.current?.getBoundingClientRect();
        const selectedViewportY = selectedRect ? selectedRect.y : 0;
        const currentScrollY = selectedRect ? selectedViewportY + window.scrollY : 0;
        const selectedYPosition = selectedViewportY + currentScrollY;

        // console.log("FEED - selectedINNER length", selectedInnerHTML?.length)
        // console.log("FEED - selectedINNER content", selectedInnerHTML?.substring(0, 512))
        // console.log("FEED all", selectedElement.current?.textContent)

        const payload = {
            location: window && parseLocation(window.location),
            feedback: {
                feedbackMethod: 'inline-feedback',
                viewportY: selectedViewportY,
                scrollY: currentScrollY,
                positionY: selectedYPosition,
                innerHTML: selectedInnerHTML512,
                textContent: selectedTextContent,
                ...data,
            },
        };

        console.log('FEEDBACK DATA', payload);
        const response = await postInlineFeedback(authState?.token, payload);

        if (response?.err) {
            console.log("COULD NOT POST INLINE FEEDBACK");
        }

        enqueueNotification({
            message: t("feedbackThanks"),
            options: {
                key: `inline-feedback-submitted-${selectedYPosition}`,
                variant: 'success',
                persist: false,
            },
        });

        removeCurrentHighlightedElement();
        removeHighlightedElementAsSelected();
        handleCloseFeedback();
    };

    return (
        <>
            <Fab
                aria-label="progress"
                color="primary"
                onClick={handleButtonClick}
                sx={{
                    visibility: !visible || feedbackOpen ? 'hidden' : 'visible',
                    transform: 'translateX(44px)',
                }}
                ref={inlineButtonRef}
            >
                <FeedbackIcon />
            </Fab>
            <InlineFeedbackDialog
                handleClose={handleCloseFeedback}
                parentContainerRef={parentContainerRef}
                onSubmit={onDialogSubmit}
                open={feedbackOpen}
                initialLeftPosition={initialFeedbackLeftPosition}
            />
        </>
    );
}

export default InlineFeedbackButton;
