import { Typography } from '@mui/material';
import React from 'react';
import Ace from '../code-editor/ace';
import MarkdownPreview from '../markdown-preview';

const contentTypes = {
    markdown: 'markdown',
    codeAce: 'code-ace',
    codeDart: 'code-dart',
    text: 'text',
};

interface Part {
    content: string;
    contentType: string;
}

interface PropTypes {
    language: string;
    part: Part;
}

const PersonalizedContentPart = (props: PropTypes) => {
    const { language, part } = props;
    switch (part.contentType) {
        case contentTypes.markdown:
            return <MarkdownPreview>{part.content}</MarkdownPreview>;
        case contentTypes.codeAce:
            return <Ace mode={language} code={part.content}></Ace>;
    }
    return <Typography>{part.content}</Typography>;
};

export default PersonalizedContentPart;
