import { ExpandMore, SpeakerNotes } from '@mui/icons-material';
import { Avatar, Box, Button, Collapse, Paper, styled, Typography } from '@mui/material';
import React from 'react';

interface ExtraInfoBoxProps {
    title: string;
    children: React.ReactNode;
}

const CollapseMore = styled(ExpandMore)(({ theme }) => ({
    transform: 'rotate(180deg)',
}));

const ExtraInfoBox = (props: ExtraInfoBoxProps) => {
    const [open, setOpen] = React.useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <Paper sx={{ my: 4 }} square>
            <Box display="flex" flexDirection="column">
                <Button
                    sx={{ p: 1.5, justifyContent: 'space-between', textTransform: 'none' }}
                    color="inherit"
                    onClick={toggleOpen}
                >
                    <Avatar
                        sx={{
                            mt: 0,
                            ml: 1.5,
                            mr: 3,
                            width: 40,
                            height: 40,
                            display: { md: 'inherit', xs: 'none' },
                            backgroundColor: 'fitech.main',
                        }}
                    >
                        <SpeakerNotes fontSize="medium" />
                    </Avatar>
                    <Typography variant="h3">{props.title}</Typography>
                    {open ? <CollapseMore fontSize="large" /> : <ExpandMore fontSize="large" />}
                </Button>

                <Collapse in={open}>
                    <Box className="togglable-programming-assignment-wrapper" sx={{ p: 2, pt: 0 }}>
                        <Box sx={{ m: 1 }} component="div">
                            <Box>{props.children}</Box>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Paper>
    );
};

export default ExtraInfoBox;
