import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { AuthContext } from "../../providers/auth-provider";

import { getModuleCollectionSlug } from "../../util/slug-util";

import { useStore } from "@nanostores/react";
import { points } from "../../stores/points.ts";

import {
  getOverallProgressPercentage,
  moduleCollectionHasPoints,
} from "../../util/point-utils";

interface OverallProgressPercentagePropsI {
  evaluatedParts: string[];
  moduleName?: string;
}

const OverallProgressPercentage = (props: OverallProgressPercentagePropsI) => {
  const { state: authState } = useContext(AuthContext);
  const pointStore = useStore(points);

  if (!authState || !authState.email) {
    console.log("Not authenticated.");
    return (
      <>
        <Typography paragraph>
          Cannot determine the current course progress as the user is not
          registered (or, it takes a while to load the points). If you have not
          yet registered, please do so now.
        </Typography>
      </>
    );
  }

  const moduleCollectionSlug = getModuleCollectionSlug();

  if (
    !authState ||
    !moduleCollectionHasPoints(pointStore, moduleCollectionSlug)
  ) {
    return (
      <>
        <Typography paragraph>
          Unable to determing points for user..
        </Typography>
      </>
    );
  }

  const progressPercentage = getOverallProgressPercentage(
    pointStore,
    moduleCollectionSlug,
    props.evaluatedParts,
  );

  return (
    <Typography paragraph>
      Currently, you have {progressPercentage}% of the overall course points.
    </Typography>
  );
};

export default OverallProgressPercentage;
