import { Box } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import InlineFeedbackButton from './inline-feedback-button';
import InlineFeedbackHint from './inline-feedback-hint';

interface InlineFeedbackTrackPropsI {
    buttonOnTrack?: boolean;
    showHint?: boolean;
    hintProbability?: number;
}

function InlineFeedbackTrack({
    buttonOnTrack,
    showHint,
    hintProbability,
}: InlineFeedbackTrackPropsI) {
    const [buttonVisible, setButtonVisible] = useState(false);
    const [buttonSeen, setButtonSeen] = useState(false);
    const inlineTrackRef = useRef<HTMLDivElement>(null);

    const handleHideButton = useCallback(() => {
        setButtonVisible(false);
    }, [setButtonVisible]);

    const handleHintSeen = () => {
        if (!buttonSeen) {
            setButtonSeen(true);
            localStorage && localStorage.setItem('inline-feedback-hint-seen', 'seen');
        }
    };

    const buttonTrackStyles = buttonOnTrack
        ? {
              borderStyle: 'none solid none none',
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: '2px',
              width: '72px',
              right: '-72px',
          }
        : {
              width: '96px',
              right: '-96px',
          };

    return (
        <>
            {Boolean(showHint) && <InlineFeedbackHint hintProbability={hintProbability} />}
            <Box
                ref={inlineTrackRef}
                sx={{
                    position: 'absolute',
                    top: '0px',
                    bottom: '0px',
                    zIndex: 1,
                    display: { sm: 'none', md: 'block' },
                    ...buttonTrackStyles,
                }}
                onMouseEnter={() => setButtonVisible(true)}
                onMouseLeave={() => {
                    setButtonVisible(false);
                    handleHintSeen();
                }}
                onMouseMove={() => setButtonVisible(true)}
            >
                <InlineFeedbackButton
                    visible={buttonVisible}
                    parentContainerRef={inlineTrackRef}
                    handleHideButton={handleHideButton}
                    onTrack={Boolean(buttonOnTrack)}
                />
            </Box>
        </>
    );
}

export default InlineFeedbackTrack;
