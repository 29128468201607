import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import PeerQuizEditorVertical from './peer-quiz-editor-vertical';
import {
    getCreationAssignmentByPoolUuid,
    getAssignmentPoolByPoolUuid,
    postCreationAssignmentSubmission,
} from '../../../http-actions/assignment-actions';
import { AuthContext } from '../../../providers/auth-provider';
import AssignmentProgressHeader from '../assignment-progress-header';
import AssignmentHeader from '../assignment-header';
import { Trans, useTranslation } from 'react-i18next';

import { useStore } from '@nanostores/react'
import { points, updatePointsAction, updatePoolPointsAction } from '../../../stores/points.ts';


export const PeerQuiz = (props: { poolUuid: string }) => {
    const pointStore = useStore(points);
    const [creationAssignment, setCreationAssignment] = React.useState();
    const [creationAssignmentPool, setCreationAssignmentPool] = React.useState();

    const { state: authState } = React.useContext(AuthContext);

    const { t } = useTranslation();

    const poolUuid = props.poolUuid;

    const assignmentPoints: [] = Array.isArray(pointStore?.pools[poolUuid])
        ? pointStore.pools[poolUuid]
        : [];

    const updatePoints = () => {
        updatePointsAction(authState.token, poolUuid, creationAssignment.assignmentId);
    };

    React.useEffect(() => {
        if (!poolUuid || !authState.token) {
            return;
        }
        console.log('Retrieving points for pool.');
        updatePoolPointsAction(authState.token, poolUuid);
    }, [authState]);

    React.useEffect(() => {
        if (authState?.token && props.poolUuid) {
            getCreationAssignmentByPoolUuid(authState.token, props.poolUuid).then((response) => {
                if (!response?.err) {
                    setCreationAssignment(response);
                }
            });
            getAssignmentPoolByPoolUuid(authState.token, props.poolUuid).then((response) => {
                if (!response?.err) {
                    setCreationAssignmentPool(response);
                }
            });
        }
    }, [authState]);

    if (!creationAssignment || !creationAssignmentPool) {
        return (
            <Paper
                sx={(theme) => ({
                    ...theme.assignmentHandout,
                })}
            >
                <Typography variant="h3" component="h2">
                    <Trans i18nKey="questionNotFoundOrLoading" />
                </Typography>
            </Paper>
        );
    }

    const submitCreationAssignment = async (data: any) => {
        if (!authState?.token) {
            alert('Need to be logged in to submit question.');
            return;
        }

        if (!creationAssignment?.id) {
            alert('Could not find assignment for submission.');
            return;
        }

        const response = await postCreationAssignmentSubmission(
            authState.token,
            creationAssignment.id,
            data,
        );

        if (response?.err) {
            return;
        }

        await updatePoints();

        return response;
    };

    const poolHasQuestionsWithPoints =
        assignmentPoints && Array.isArray(assignmentPoints) && assignmentPoints.length > 0;
    const poolPoints = poolHasQuestionsWithPoints
        ? assignmentPoints.map((x) => x.points).reduce((a, b) => a + b, 0)
        : 0;
    const poolMaxPoints = creationAssignmentPool && creationAssignmentPool.maxPoints;

    const nCreationSubmissions = poolMaxPoints && poolMaxPoints / creationAssignment?.maxPoints;
    const quizzesCreated =
        poolPoints && creationAssignment && poolPoints / assignmentPoints[0]?.maxPoints;

    const hasCreatedEnough =
        poolHasQuestionsWithPoints && quizzesCreated >= nCreationSubmissions && creationAssignment;

    console.log('CA_HMM', creationAssignment, creationAssignmentPool);

    return (
        <Box
            component="section"
            className={`peer-quiz-${props.poolUuid}`}
            sx={(theme) => ({
                backgroundColor: theme.palette.primary.bg,
                margin: theme.spacing(0, -500),
                marginBottom: theme.spacing(6),
                marginTop: theme.spacing(6),
                padding: theme.spacing(0, 500, 2, 500),
            })}
        >
            <AssignmentProgressHeader points={poolPoints} maxPoints={poolMaxPoints} />
            <AssignmentHeader
                id={props.poolUuid}
                assignmentsDoneText={t('assignmentsDoneText')} // TRANS
                points={poolPoints}
                maxPoints={poolMaxPoints}
                nCompleted={quizzesCreated ?? 0}
                nAssignments={nCreationSubmissions ?? 0}
            />
            {hasCreatedEnough ? (
                <Paper
                    sx={(theme) => ({
                        ...theme.assignmentHandout,
                    })}
                >
                    <Typography variant="h3" component="h2">
                        <Trans i18nKey="quizzesCreatedMessage" />
                    </Typography>
                </Paper>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: 4,
                    }}
                >
                    <PeerQuizEditorVertical submitCreationAssignment={submitCreationAssignment} />
                </Box>
            )}
        </Box>
    );
};

export default PeerQuiz;
