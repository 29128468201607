/* eslint-disable */

import React, { useContext, useEffect } from "react";

import {
  getFeedbackStatus,
  postFeedback,
} from "../../http-actions/feedback-actions";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import { AuthContext } from "../../providers/auth-provider";

import { Trans, useTranslation } from "react-i18next";

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
CHANGE SUBMIT BUTTON TO FITECH STYLE
USE THEME DEFINITIONS
*/

const sliderMarks = [...Array(11).keys()].map((i) => {
  return { value: i, label: i.toString() };
});

const sxSliderStyle = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  },
};

const sxSliderStyleExp = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="4"]': {
      transform: "translateX(-100%)",
    },
  },
};

const SliderQuestion = (props) => {
  const { t } = useTranslation();

  const optionMarks = [
    {
      value: 1,
      label: t("stronglyDisagree"),
    },
    {
      value: 7,
      label: t("stronglyAgree"),
    },
  ];

  return (
    <>
      <Typography
        id={props.id}
        variant="body2"
        gutterBottom
        sx={{ marginTop: "1em" }}
      >
        <Trans i18nKey={props.id} />
      </Typography>
      <Slider
        size="small"
        sx={sxSliderStyle}
        name={props.id}
        defaultValue={4}
        min={1}
        max={7}
        aria-labelledby={props.id}
        step={1}
        valueLabelDisplay="auto"
        track={false}
        marks={optionMarks}
        onChange={props.handleChange(props.id)}
      />
    </>
  );
};

const valueText = (value) => {
  return `${value}`;
};

const Survey = (props) => {
  const { t } = useTranslation();

  const { state: authState } = useContext(AuthContext);
  const [lastAnsweredAt, setLastAnsweredAt] = React.useState();
  const [showForm, setShowForm] = React.useState(true);
  const [location, setLocation] = React.useState("undefined");

  const [feedback, setFeedback] = React.useState<State>({
    experienceInLinesOfCodePriorToY1: null,
    y1ExerciseRoundsCompleted: null,
    writtenGraphicsProgramsBefore: null,
    itIsUsefulForMeToKnowHowToProgram: null,
    programmingIsBoring: null,
    programmingIsFun: null,
    howOld: null,
    gender: null,
    round1: false,
    round2: false,
    round3: false,
  });

  const programSizeInLines = [
    {
      value: 0,
      label: t("0"),
    },
    {
      value: 1,
      label: `${t("under")} 50`,
    },
    {
      value: 2,
      label: "50-500",
    },
    {
      value: 3,
      label: "501-5000",
    },
    {
      value: 4,
      label: `${t("over")} 5000`,
    },
  ];

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const fb = { ...feedback, [prop]: event.target.value };
      setFeedback(fb);

      setTimeout(() => {
        postFeedback(authState.token, location, fb);
      }, 500);
    };

  const handleLinesOfCodeExperienceChange = (
    event: any,
    newValue: number | number[],
  ) => {
    const fb = { ...feedback, experienceInLinesOfCodePriorToY1: newValue };
    setFeedback(fb);

    setTimeout(() => {
      postFeedback(authState.token, location, fb);
    }, 500);
  };

  const handleCheckboxChange = (
    event: any,
    newValue: boolean,
  ) => {
    const selectedValue = event.target.defaultValue;
    const fb = { ...feedback, [selectedValue]: newValue };
    setFeedback(fb);

    setTimeout(() => {
      postFeedback(authState.token, location, fb);
    }, 500);
  };

  const handleAgeChange = (event) => {
    console.log(event);
    const selectedValue = event.target.value;
    const fb = { ...feedback, age: selectedValue };
    setFeedback(fb);

    setTimeout(() => {
      postFeedback(authState.token, location, fb);
    }, 500);
  };

  const handleGenderChange = (event) => {
    const selectedValue = event.target.value;

    const fb = { ...feedback, gender: selectedValue };
    setFeedback(fb);

    setTimeout(() => {
      postFeedback(authState.token, location, fb);
    }, 500);
  };

  const handleWrittenGraphicsProgramsBefore = (event) => {
    const selectedValue = event.target.value;
    const fb = { ...feedback, writtenGraphicsProgramsBefore: selectedValue };
    setFeedback(fb);

    setTimeout(() => {
      postFeedback(authState.token, location, fb);
    }, 500);
  };

  useEffect(() => {
    setShowForm(!Boolean(lastAnsweredAt));
  }, [lastAnsweredAt]);

  const submitFeedback = () => {
    postFeedback(authState.token, location, feedback);
    setShowForm(false);

    setLastAnsweredAt(new Date());
  };

  const getLastFeedbackTime = async (loc) => {
    const status = await getFeedbackStatus(authState.token, loc);
    if (
      status.last_feedback && status.last_feedback[0] &&
      status.last_feedback[0].max
    ) {
      setLastAnsweredAt(new Date(status.last_feedback[0].max));
    }
  };

  useEffect(() => {
    if (!authState.token) {
      return;
    }

    let path = window.location.pathname.replace(/\/+$/, "");
    path = path[0] == "/" ? path.substr(1) : path;
    const pathArray = path.split("/");

    const loc = pathArray[0] + "-" + pathArray[1] + "-" + pathArray[2];
    setLocation(loc);
    getLastFeedbackTime(loc);
  }, [authState]);

  return (
    <>
      {showForm && (
        <>
          <Typography
            id="largest-program-in-lines-of-code"
            gutterBottom
            sx={{ mt: 2 }}
          >
            <Trans i18nKey="programmingExperienceLargestProgramInLinesOfCodePriorY1" />
          </Typography>
          <Slider
            sx={sxSliderStyleExp}
            getAriaValueText={valueText}
            defaultValue={0}
            min={0}
            max={4}
            aria-labelledby="largest-program-in-lines-of-code"
            step={1}
            valueLabelDisplay="auto"
            track={false}
            marks={programSizeInLines}
            onChangeCommitted={handleLinesOfCodeExperienceChange}
          />
          <React.Fragment>
            <FormControl component="fieldset">
              <Typography
                id="y1ExerciseRoundsCompleted"
                variant="body2"
                gutterBottom
                sx={{ marginTop: "1em" }}
              >
                <Trans i18nKey="y1ExerciseRoundsCompleted" />
              </Typography>
              <FormGroup aria-label="y1ExerciseRoundsCompleted">
                <FormControlLabel
                  value="round1"
                  control={
                    <Checkbox
                      checked={feedback.round1}
                      onChange={handleCheckboxChange}
                      name="round1"
                    />
                  }
                  label={<Trans i18nKey="round1" />}
                />
                <FormControlLabel
                  value="round2"
                  control={
                    <Checkbox
                      checked={feedback.round2}
                      onChange={handleCheckboxChange}
                      name="round2"
                    />
                  }
                  label={<Trans i18nKey="round2" />}
                />
                <FormControlLabel
                  value="round3"
                  control={
                    <Checkbox
                      checked={feedback.round3}
                      onChange={handleCheckboxChange}
                      name="round3"
                    />
                  }
                  label={<Trans i18nKey="round3" />}
                />
              </FormGroup>
            </FormControl>
          </React.Fragment>
          <br />
          <React.Fragment>
            <FormControl component="fieldset">
              <Typography
                id="writtenGraphicsProgramsBefore"
                variant="body2"
                gutterBottom
                sx={{ marginTop: "1em" }}
              >
                <Trans i18nKey="writtenGraphicsProgramsBeforeQuestion" />
              </Typography>
              <RadioGroup
                aria-label="writtenGraphicsProgramsBefore"
                name="writtenGraphicsProgramsBefore"
                value={feedback.writtenGraphicsProgramsBefore}
                onChange={handleWrittenGraphicsProgramsBefore}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label={<Trans i18nKey="yes" />}
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label={<Trans i18nKey="no" />}
                />
                <FormControlLabel
                  value="notSure"
                  control={<Radio />}
                  label={<Trans i18nKey="notSure" />}
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
          <br />
          <SliderQuestion
            id="itIsUsefulForMeToKnowHowToProgram"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="programmingIsBoring"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="programmingIsFun"
            handleChange={handleChange}
          />
          <React.Fragment>
            <Typography
              id="howOld"
              variant="body2"
              gutterBottom
              sx={{ marginTop: "1em" }}
            >
              <Trans i18nKey="howOldQuestion" />
            </Typography>
            <TextField
              onChange={handleChange("age")}
              name="howOld"
              label={<Trans i18nKey="age" />}
              aria-labelledby="howOld"
              variant="outlined"
              type="number"
            />
          </React.Fragment>
          <br />
          <React.Fragment>
            <FormControl component="fieldset">
              <Typography
                id="genderQuestion"
                variant="body2"
                gutterBottom
                sx={{ marginTop: "1em" }}
              >
                <Trans i18nKey="yourGenderQuestion" />
              </Typography>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={feedback.gender}
                onChange={handleGenderChange}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<Trans i18nKey="female" />}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={<Trans i18nKey="male" />}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={<Trans i18nKey="nonBinary" />}
                />
                <FormControlLabel
                  value="prefer-not-to-disclose"
                  control={<Radio />}
                  label={<Trans i18nKey="preferNotToDisclose" />}
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={submitFeedback}
            sx={{ marginTop: "1em" }}
          >
            <Trans i18nKey="sendSurvey" />
          </Button>
        </>
      )}
      {lastAnsweredAt && !showForm && (
        <>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ mt: 1 }}
            fontStyle="italic"
          >
            <Trans
              i18nKey="surveySentThanks"
              values={{ lastAnsweredAt: lastAnsweredAt.toLocaleString() }}
            />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowForm(true)}
            sx={{ mt: 1 }}
          >
            <Trans i18nKey="fillSurveyAgain" />
          </Button>
        </>
      )}
    </>
  );
};

const PytamaroBackgroundSurveyForm = (props) => {
  return (
    <Paper sx={{ my: 2, p: 2 }}>
      <Survey {...props} />
    </Paper>
  );
};

export default PytamaroBackgroundSurveyForm;
