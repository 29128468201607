import { Button, Paper, Typography } from "@mui/material";
import React from "react";

const ROCK = 0;
const PAPER = 1;
const SCISSORS = 2;

const RockPaperScissors = (props) => {
  const playUntil = props.playUntil ?? 20;

  const [playerHistory, setPlayerHistory] = React.useState([]);

  const [lastOwnAnswer, setLastOwnAnswer] = React.useState("-");
  const [lastComputerGuess, setLastComputerGuess] = React.useState("-");
  const [playerVictories, setPlayerVictories] = React.useState(0);
  const [computerVictories, setComputerVictories] = React.useState(0);

  const reset = () => {
    setPlayerHistory([]);
    setLastOwnAnswer("-");
    setLastComputerGuess("-");
    setPlayerVictories(0);
    setComputerVictories(0);
  };

  const moveAsString = (move) => {
    if (move === ROCK) {
      return "Rock";
    } else if (move === PAPER) {
      return "Paper";
    } else if (move === SCISSORS) {
      return "Scissors";
    } else {
      return move;
    }
  };

  const isDraw = (playerMove, computerMove) => {
    return playerMove === computerMove;
  };

  const playerWon = (playerMove, computerMove) => {
    return (playerMove === ROCK && computerMove === SCISSORS) ||
      (playerMove === PAPER && computerMove === ROCK) ||
      (playerMove === SCISSORS && computerMove === PAPER);
  };

  const randomMove = () => {
    return Math.floor(Math.random() * 3);
  };

  const selectOption = (selectedOption) => {
    console.log("selected " + selectedOption);

    setLastOwnAnswer(selectedOption);

    const computerGuess = getComputerGuess();
    setLastComputerGuess(computerGuess);

    if (isDraw(selectedOption, computerGuess)) {
      setComputerVictories(computerVictories + 1);
      setPlayerVictories(playerVictories + 1);
    } else if (playerWon(selectedOption, computerGuess)) {
      setPlayerVictories(playerVictories + 1);
    } else {
      setComputerVictories(computerVictories + 1);
    }

    playerHistory.push(selectedOption);
  };

  const textToMove = (move) => {
    if (move === 'R') {
      return ROCK;
    } else if (move === 'P') {
      return PAPER;
    } else { //  (move === 'S')
      return SCISSORS;
    } 
  }

  const getComputerGuess = () => {
    if (props.fixedProbabilities) {
      const randomValue = Math.random();
      let currentLimit = 0;

      for (const [key, value] of Object.entries(props.fixedProbabilities)) {
        if (randomValue >= currentLimit && randomValue < currentLimit + value) {
          return textToMove(key);
        }

        currentLimit += value;
      }

      return randomMove();
    }


    if (props.useLastPlayerMove) {
      if (playerHistory.length < 1) {
        return randomMove();
      }

      const lastMove = playerHistory[playerHistory.length - 1];
      if (lastMove === ROCK) {
        return PAPER;
      }

      if (lastMove === PAPER) {
        return SCISSORS;
      }

      if (lastMove === SCISSORS) {
        return ROCK;
      }

      return randomMove();
    }

    if (playerHistory.length < 3) {
      return PAPER;
    }

    const map = new Map();

    for (let i = 2; i < playerHistory.length; i++) {
      const one = playerHistory[i - 2];
      const two = playerHistory[i - 1];
      const three = playerHistory[i];

      const combo = `${one}-${two}-${three}`;

      if (!map.has(combo)) {
        map.set(combo, 0);
      }

      map.set(combo, map.get(combo) + 1);
    }

    const prevToLast = playerHistory[playerHistory.length - 2];
    const last = playerHistory[playerHistory.length - 1];

    const leadingToRockSequence = `${prevToLast}-${last}-${ROCK}`;
    const leadingToPaperSequence = `${prevToLast}-${last}-${PAPER}`;
    const leadingToScissorsSequence = `${prevToLast}-${last}-${SCISSORS}`;

    const leadingToRockCount = map.has(leadingToRockSequence)
      ? map.get(leadingToRockSequence)
      : 0;
    const leadingToPaperCount = map.has(leadingToPaperSequence)
      ? map.get(leadingToPaperSequence)
      : 0;
    const leadingToScissorsCount = map.has(leadingToScissorsSequence)
      ? map.get(leadingToScissorsSequence)
      : 0;

    if (
      leadingToRockCount > leadingToPaperCount &&
      leadingToRockCount > leadingToScissorsCount
    ) {
      return PAPER;
    }

    if (
      leadingToPaperCount > leadingToRockCount &&
      leadingToPaperCount > leadingToScissorsCount
    ) {
      return SCISSORS;
    }

    if (
      leadingToScissorsCount > leadingToPaperCount &&
      leadingToScissorsCount > leadingToRockCount
    ) {
      return ROCK;
    }

    return randomMove();
  };

  if (playerVictories >= playUntil && computerVictories >= playUntil) {
    return (
      <Paper
        sx={(theme) => ({
          border: "1px solid black",
          "& > *": {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
          },
        })}
      >
        <Typography variant="h3" gutterBottom>
          Rock paper scissors!
        </Typography>
        <Typography gutterBottom>
          It's a tie!
        </Typography>
        <div>
          <Button
            sx={{ marginRight: "1em" }}
            onClick={() => reset()}
            color="primary"
            variant="contained"
          >
            Try again!
          </Button>
        </div>
      </Paper>
    );
  }

  if (playerVictories >= playUntil) {
    return (
      <Paper
        sx={(theme) => ({
          border: "1px solid black",
          "& > *": {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
          },
        })}
      >
        <Typography variant="h3" gutterBottom>
          Rock paper scissors!
        </Typography>
        <Typography gutterBottom>
          You won! Congratulations!
        </Typography>
        <div>
          <Button
            sx={{ marginRight: "1em" }}
            onClick={() => reset()}
            color="primary"
            variant="contained"
          >
            Try again!
          </Button>
        </div>
      </Paper>
    );
  }

  if (computerVictories >= playUntil) {
    return (
      <Paper
        sx={(theme) => ({
          border: "1px solid black",
          "& > *": {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
          },
        })}
      >
        <Typography variant="h3" gutterBottom>
          Rock paper scissors!
        </Typography>
        <Typography gutterBottom>
          The computer got you! Nice try!
        </Typography>
        <div>
          <Button
            sx={{ marginRight: "1em" }}
            onClick={() => reset()}
            color="primary"
            variant="contained"
          >
            Try again!
          </Button>
        </div>
      </Paper>
    );
  }

  return (
    <Paper
      sx={(theme) => ({
        border: "1px solid black",
        "& > *": {
          margin: theme.spacing(1),
          padding: theme.spacing(1),
        },
      })}
    >
      <Typography gutterBottom>
        Rock beats scissors, scissors beats paper, paper beats rock. If both
        players choose the same, it's a tie. Play against the computer until the
        score of {playUntil}. Pick one to of the three buttons to start.
      </Typography>
      <div>
        <Button
          sx={{ marginRight: "1em" }}
          onClick={() => selectOption(ROCK)}
          color="primary"
          variant="contained"
        >
          {moveAsString(ROCK)}
        </Button>
        <Button
          sx={{ marginRight: "1em" }}
          onClick={() => selectOption(PAPER)}
          color="primary"
          variant="contained"
        >
          {moveAsString(PAPER)}
        </Button>
        <Button
          sx={{ marginRight: "1em" }}
          onClick={() => selectOption(SCISSORS)}
          color="primary"
          variant="contained"
        >
          {moveAsString(SCISSORS)}
        </Button>
      </div>

      <Typography gutterBottom>
        Current score: You {playerVictories} - Computer {computerVictories}
      </Typography>

      <Typography gutterBottom>
        Your last choice: {moveAsString(lastOwnAnswer)}
      </Typography>

      <Typography gutterBottom>
        Computer's last choice: {moveAsString(lastComputerGuess)}
      </Typography>
    </Paper>
  );
};

export default RockPaperScissors;
