/* eslint-disable */

import React, { useContext, useEffect } from 'react';

import { getFeedbackStatus, postFeedback } from '../../http-actions/feedback-actions';

import { Button, TextField, Typography, Paper } from '@mui/material';

import Slider from '@mui/material/Slider';
import { AuthContext } from '../../providers/auth-provider';

import { Trans, useTranslation } from 'react-i18next';

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
CHANGE SUBMIT BUTTON TO FITECH STYLE
USE THEME DEFINITIONS
*/

const sliderMarks = [...Array(11).keys()].map((i) => {
    return { value: i, label: i.toString() };
});

const sxSliderStyle = {
    '.MuiSlider-markLabel': {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    },
};

const SliderQuestion = (props) => {
    const { t } = useTranslation();

    const optionMarks = [
        {
            value: 1,
            label: t('stronglyDisagree'),
        },
        {
            value: 7,
            label: t('stronglyAgree'),
        },
    ];

    return (
        <>
            <Typography id={props.id} variant="body2" gutterBottom sx={{ marginTop: '1em' }}>
                {props.label ?? <Trans i18nKey={props.id} /> }
            </Typography>
            <Slider
                size="small"
                sx={sxSliderStyle}
                name={props.id}
                defaultValue={4}
                min={1}
                max={7}
                aria-labelledby={props.id}
                step={1}
                valueLabelDisplay="auto"
                track={false}
                marks={optionMarks}
                onChange={props.handleChange(props.id)}
            />
        </>
    );
};

const Feedback = (props) => {
    const { state: authState } = useContext(AuthContext);
    const [lastAnsweredAt, setLastAnsweredAt] = React.useState();
    const [showForm, setShowForm] = React.useState(true);
    const [location, setLocation] = React.useState('undefined');

    const [feedback, setFeedback] = React.useState<State>({
        beneficialForMyLearning: null,
        wouldPreferToSeeInOtherExercises: null,
        estimateUsefulForMe: null,
        improvementSuggestions: null,
        estimateSeemedAccurateToMe: null,
        estimateMotivated: null,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback({ ...feedback, [prop]: event.target.value });
    };

    useEffect(() => {
        setShowForm(!Boolean(lastAnsweredAt));
    }, [lastAnsweredAt]);

    const submitFeedback = () => {
        postFeedback(authState.token, location, feedback);
        setShowForm(false);

        setLastAnsweredAt(new Date());
    };

    const getLastFeedbackTime = async (loc) => {
        const status = await getFeedbackStatus(authState.token, loc);
        if (status.last_feedback && status.last_feedback[0] && status.last_feedback[0].max) {
            setLastAnsweredAt(new Date(status.last_feedback[0].max));
        }
    };

    useEffect(() => {
        if (!authState.token) {
            return;
        }

        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        const pathArray = path.split('/');

        const loc = pathArray[0] + '-' + pathArray[1] + "-" + pathArray[2];
        setLocation(loc);
        getLastFeedbackTime(loc);
    }, [authState]);

    return (
        <>
            {showForm && (
                <>
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Feedback
                    </Typography>
                    <Typography paragraph>
                        On a scale from 1 to 7, where 1 = Strongly disagree and 7 = Strongly agree, please choose the options that best describe your opinion on the following statements.
                    </Typography>
                    <SliderQuestion
                        id="estimateUsefulForMe"
                        label="The progress estimate functionality seemed useful to me."
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        id="estimateSeemedAccurateToMe"
                        label="The progress estimate seemed accurate to me."
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        id="estimateMotivated"
                        label="The progress estimate motivated me to keep working on the problem."
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        id="beneficialForMyLearning"
                        label="I believe that the progress estimate functionality would be beneficial for my learning."
                        handleChange={handleChange}
                    />
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Improvement suggestions
                    </Typography>
                    <Typography
                        id="improvementSuggestions"
                        variant="body2"
                        gutterBottom
                        sx={{ marginTop: '1em' }}
                    >
                        How would you improve the progress estimate functionality?
                    </Typography>
                    <TextField
                        onChange={handleChange('improvementSuggestions')}
                        name="improvementSuggestions"
                        multiline
                        rows={4}
                        aria-labelledby="improvementSuggestions"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                    />
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitFeedback}
                            sx={{ marginTop: '1em' }}
                        >
                            <Trans i18nKey="sendFeedback" />
                        </Button>
                    </React.Fragment>
                </>
            )}
            {lastAnsweredAt && !showForm && (
                <>
                    <Typography variant="body2" gutterBottom sx={{ mt: 1 }} fontStyle="italic">
                        <Trans
                            i18nKey="feedbackSentThanks"
                            values={{ lastAnsweredAt: lastAnsweredAt.toLocaleString() }}
                        />
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowForm(true)}
                        sx={{ mt: 1 }}
                    >
                        <Trans i18nKey="sendNewFeedback" />
                    </Button>
                </>
            )}
        </>
    );
};

const AIFFeedbackFeedbackForm = (props) => {
    return (
        <Paper sx={{ my: 2, p: 2 }}>
            <Feedback {...props} />
        </Paper>
    );
};

export default AIFFeedbackFeedbackForm;
