import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import {
    getPeerReviewFormForAdminByAssignmentUuid,
    getSubmissionForReviewZip,
    submitReview,
} from '../../../http-actions/review-actions';

import { AuthContext } from '../../../providers/auth-provider';
import AssignmentHeader from '../../assignment/assignment-header';
import ResultInfo from '../../assignment/programming/result-info';
import Button from '../../button';
import PeerReviewForm from '../review-form';

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.fitech.contrastText,
            backgroundColor: theme.palette.fitech.main,
            transition: theme.transitions.create(['color', 'background-color']),
            '&:hover': {
                color: theme.palette.common.black,
            },
        },
    }),
);
*/

interface InputProps {
    assignmentUuid: string;
    fetchSubmittedReviews: () => unknown;
    nCompleted: number;
    download?: boolean;
}

export default function PeerReviewAssignment(props: InputProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(null);
    const [resultInfoMessage, setResultInfoMessage] = useState(null);
    const [reviewAssignmentReviewId, setReviewAssignmentReviewId] = useState(null);
    const [showReview, setShowReview] = useState(false);
    console.log('\n\nreview assingment gg\n\n');
    console.log(reviewAssignmentReviewId);
    console.log(props);

    const { state: authState } = useContext(AuthContext);

    const fetchFormData = async (showReview: boolean) => {
        console.log('fetching form data');

        // NEW ENDPOINT TO GET UNLIMITED REVIEW FORMS IF YOU ARE "ADMIN / TA"
        const newReviewFormData = await getPeerReviewFormForAdminByAssignmentUuid(
            authState.token,
            props.assignmentUuid,
        );

        if (newReviewFormData.form) {
            setFormData(newReviewFormData.form);
            setReviewAssignmentReviewId(newReviewFormData.reviewAssignmentReviewId);
            setShowReview(showReview);
        } else {
            if (newReviewFormData.failureMessage)
                setResultInfoMessage({
                    type: 'failure',
                    message: newReviewFormData.failureMessage,
                }); // TODO: localization
            if (newReviewFormData.err)
                setResultInfoMessage({ type: 'error', message: newReviewFormData.err.toString() });
        }
    };

    const onSubmit = async (data: any) => {
        // translate
        const toSubmit = confirm(
            'Are you sure you want to submit? The review cannot be changed later.',
        );
        if (toSubmit) {
            const response = await submitReview(authState.token, reviewAssignmentReviewId, data);

            if (!response.err) {
                props.fetchSubmittedReviews();
                setShowReview(false);
                setResultInfoMessage({ type: 'success', message: 'reviewSubmitted' });
            } else {
                setResultInfoMessage({
                    type: 'error',
                    message: 'unexpectedErrorOnSubmitReview',
                });
            }
        }
    };

    const triggerReviewFetch = async () => {
        await fetchFormData(true);
    };

    // TODO FIX NAME FOR FILE TO HAVE THE CURRENT REVIEW "INDEX"
    const triggerCodeZipDownload = () => {
        console.log('DOWNLOAD');
        void getSubmissionForReviewZip(authState.token, reviewAssignmentReviewId).then((data) => {
            if (data.err) return;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `review-submission-${props.nCompleted + 1}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return (
        <>
            <AssignmentHeader
                id={'review-assingment-id-todo'}
                nCompleted={props.nCompleted}
                nAssignments="–"
                assignmentsDoneText={t('reviewsSubmitted')}
            />
            {showReview ? (
                <PeerReviewForm
                    {...props}
                    onSubmit={onSubmit}
                    downloadCode={props.download && triggerCodeZipDownload}
                    downloadLink={''}
                    formData={formData}
                />
            ) : (
                <Button
                    mx={1}
                    my={2}
                    // className={classes.button}
                    onClick={triggerReviewFetch}
                    kind="fitech"
                    color="inherit"
                >
                    {props.nCompleted === 0 ? 'Get review' : 'Get next review'}
                </Button>
            )}
            {resultInfoMessage && (
                <ResultInfo
                    messageKey={resultInfoMessage.message}
                    type={resultInfoMessage.type}
                    endIcon={<Close />}
                    onClick={() => setResultInfoMessage(null)}
                />
            )}
        </>
    );
}
