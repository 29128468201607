/* eslint-disable */

import React, { useContext, useEffect } from 'react';

import { getFeedbackStatus, postFeedback } from '../../http-actions/feedback-actions';

import { Button, TextField, Typography, Paper } from '@mui/material';

import Slider from '@mui/material/Slider';
import { AuthContext } from '../../providers/auth-provider';

import { Trans, useTranslation } from 'react-i18next';

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
CHANGE SUBMIT BUTTON TO FITECH STYLE
USE THEME DEFINITIONS
*/

const sliderMarks = [...Array(11).keys()].map((i) => {
    return { value: i, label: i.toString() };
});

const sxSliderStyle = {
    '.MuiSlider-markLabel': {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    },
};

const SliderQuestion = (props) => {
    const { t } = useTranslation();

    const optionMarks = [
        {
            value: 1,
            label: t('stronglyDisagree'),
        },
        {
            value: 7,
            label: t('stronglyAgree'),
        },
    ];

    return (
        <>
            <Typography id={props.id} variant="body2" gutterBottom sx={{ marginTop: '1em' }}>
                <Trans i18nKey={props.id} />
            </Typography>
            <Slider
                size="small"
                sx={sxSliderStyle}
                name={props.id}
                defaultValue={4}
                min={1}
                max={7}
                aria-labelledby={props.id}
                step={1}
                valueLabelDisplay="auto"
                track={false}
                marks={optionMarks}
                onChange={props.handleChange(props.id)}
            />
        </>
    );
};

const Feedback = (props) => {
    const { state: authState } = useContext(AuthContext);
    const [lastAnsweredAt, setLastAnsweredAt] = React.useState();
    const [showForm, setShowForm] = React.useState(true);
    const [location, setLocation] = React.useState('undefined');

    const [feedback, setFeedback] = React.useState<State>({
        feedbackCanSeeHowMaterialsFit: null,
        feedbackContentInteresting: null,
        feedbackCertainThatCanLearn: null,
        feedbackWouldBenefitFromDeadlines: null,
        feedbackFeelOverwhelmed: null,
        feedbackTryExamplesLocally: null,
        feedbackAssignmentsTooDifficult: null,
        feedbackBeenSystematicInStudying: null,
        hoursStudied: null,
        improvementSuggestions: null,
        keyLearningOutcome: null,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback({ ...feedback, [prop]: event.target.value });
    };

    useEffect(() => {
        setShowForm(!Boolean(lastAnsweredAt));
    }, [lastAnsweredAt]);

    const submitFeedback = () => {
        postFeedback(authState.token, location, feedback);
        setShowForm(false);

        setLastAnsweredAt(new Date());
    };

    const getLastFeedbackTime = async (loc) => {
        const status = await getFeedbackStatus(authState.token, loc);
        if (status.last_feedback && status.last_feedback[0] && status.last_feedback[0].max) {
            setLastAnsweredAt(new Date(status.last_feedback[0].max));
        }
    };

    useEffect(() => {
        if (!authState.token) {
            return;
        }

        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        const pathArray = path.split('/');

        const loc = pathArray[0] + '-' + pathArray[1] + "-" + pathArray[2];
        setLocation(loc);
        getLastFeedbackTime(loc);
    }, [authState]);

    return (
        <>
            {showForm && (
                <>
                    <Typography variant="h6" gutterBottom>
                        Key learning outcome
                    </Typography>
                    <Typography
                        id="keyLearningOutcome"
                        variant="body2"
                        gutterBottom
                        sx={{ marginTop: '1em' }}
                    >
                        What would you consider as the key learning outcome of the chapter?
                    </Typography>
                    <TextField
                        onChange={handleChange('keyLearningOutcome')}
                        name="keyLearningOutcome"
                        multiline
                        rows={4}
                        aria-labelledby="keyLearningOutcome"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                    />
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Improvement suggestions
                    </Typography>
                    <Typography
                        id="improvementSuggestions"
                        variant="body2"
                        gutterBottom
                        sx={{ marginTop: '1em' }}
                    >
                        The materials are being prepared so that they could act as self-study materials in the future, after the three iterations of the course. Highlight one thing that you would wish to emphasize, include, change, or remove in the present chapter.
                    </Typography>
                    <TextField
                        onChange={handleChange('improvementSuggestions')}
                        name="improvementSuggestions"
                        multiline
                        rows={4}
                        aria-labelledby="improvementSuggestions"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                    />
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Feedback
                    </Typography>
                    <Typography paragraph>
                        Please choose the options that best describe your opinion on the following statements.
                    </Typography>
                    <SliderQuestion
                        id="feedbackCanSeeHowMaterialsFit"
                        handleChange={handleChange}
                    />
                    <SliderQuestion id="feedbackContentInteresting" handleChange={handleChange} />
                    <SliderQuestion
                        id="feedbackAssignmentsTooDifficult"
                        handleChange={handleChange}
                    />
                    <React.Fragment>
                        <Typography
                            id="hoursStudied"
                            variant="body2"
                            gutterBottom
                            sx={{ marginTop: '1em' }}
                        >
                            <Trans i18nKey="hoursStudiedQuestion" />
                        </Typography>
                        <TextField
                            onChange={handleChange('hoursStudied')}
                            name="hoursStudied"
                            label={<Trans i18nKey="hoursStudiedLabel" />}
                            aria-labelledby="hoursStudied"
                            variant="outlined"
                            type="number"
                        />
                        <Typography />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitFeedback}
                            sx={{ marginTop: '1em' }}
                        >
                            <Trans i18nKey="sendFeedback" />
                        </Button>
                    </React.Fragment>
                </>
            )}
            {lastAnsweredAt && !showForm && (
                <>
                    <Typography variant="body2" gutterBottom sx={{ mt: 1 }} fontStyle="italic">
                        <Trans
                            i18nKey="feedbackSentThanks"
                            values={{ lastAnsweredAt: lastAnsweredAt.toLocaleString() }}
                        />
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowForm(true)}
                        sx={{ mt: 1 }}
                    >
                        <Trans i18nKey="sendNewFeedback" />
                    </Button>
                </>
            )}
        </>
    );
};

const LLMWithSEFeedbackForm = (props) => {
    return (
        <Paper sx={{ my: 2, p: 2 }}>
            <Feedback {...props} />
        </Paper>
    );
};

export default LLMWithSEFeedbackForm;
