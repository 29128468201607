import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

const SelectItem = ({ label, items, onChange }) => {
  const [selection, setSelection] = React.useState("");

  const handleChange = (event) => {
    setSelection(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`select-item-${label}`}>{label}</InputLabel>
      <Select
        labelId="theme-simple-select-label"
        id="theme-simple-select"
        value={selection}
        label={label}
        onChange={handleChange}
      >
        {items && items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ThemeSelection = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <SelectItem
          label="Theme"
          items={props.options["themes"]}
          onChange={(newTheme) => props.changeSelection("theme", newTheme)}
        />
      </Grid>
      <Grid item xs>
        <SelectItem
          label="Concept"
          items={props.options["concepts"]}
          onChange={(newConcept) => props.changeSelection("concept", newConcept)}
        />
      </Grid>
      <Grid item xs>
        <SelectItem
          label="Difficulty"
          items={props.options["difficulty"]}
          onChange={(newDifficulty) =>
            props.changeSelection("difficulty", newDifficulty)}
        />
      </Grid>
    </Grid>
  );
};

export default ThemeSelection;