import config from "../config";
import {
  handleResponseError,
  handleResponseSuccess,
} from "./http-response-handler";

import { getAxiosClient } from "./axios-client";

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const getRemedialExerciseChapterData = (
  token: string,
  chapter: string,
) => {
  console.log(`requesting points for user ${token}`);
  return axios.get(`api/ai-generated-practice-exercises/chapters/${chapter}`, {
    headers: { Authorization: token },
  })
    .then(
      handleResponseSuccess,
      handleResponseError,
    );
};

export const getExerciseWithSelections = (
  token: string,
  chapter: string,
  selections: object,
) => {
  console.log(
    `requesting exercise for chapter ${chapter} with the following selections`,
  );
  return axios.post(
    `api/ai-generated-practice-exercises/chapters/${chapter}/exercises`,
    selections,
    {
      headers: { Authorization: token },
    },
  )
    .then(
      handleResponseSuccess,
      handleResponseError,
    );
};

export const postFeedback = (
  token: string,
  feedback: object,
) => {
  console.log(
    `posting feedback`,
  );
  return axios.post(
    `api/ai-generated-practice-exercises/feedbacks`,
    feedback,
    {
      headers: { Authorization: token },
    },
  )
    .then(
      handleResponseSuccess,
      handleResponseError,
    );
};


export const submitExerciseForGrading = (
  token: string,
  chapter: string,
  exercise: string,
  data: object,
) => {
  console.log(
    `submitting exercise from chapter ${chapter}`,
  );
  return axios.post(
    `api/ai-generated-practice-exercises/chapters/${chapter}/exercises/${exercise}`,
    data,
    {
      headers: { Authorization: token },
    },
  )
    .then(
      handleResponseSuccess,
      handleResponseError,
    );
};

export const gradeAllExercises = (
  token: string,
) => {
  return axios.get(
    `api/ai-generated-practice-exercises/grade-all`,
    {
      headers: { Authorization: token },
    },
  )
    .then(
      handleResponseSuccess,
      handleResponseError,
    );
};

// export const getModuleCollectionSummaryPoints = (token: string) => {
//   console.log(`requesting module collection summary points for user ${token}`);
//   return axios.get("api/points-v0/module-collections/me", {
//     headers: { Authorization: token },
//   })
//     .then(
//       handleResponseSuccess,
//       handleResponseError,
//     );
// };

// export const getPointsForPoolAssignments = (
//   token: string,
//   poolUuid: string,
// ) => {
//   console.log(
//     `requesting programming assignment points for pool ${poolUuid}...`,
//   );
//   return axios.get(`api/points-v0/pools/${poolUuid}`, {
//     headers: { Authorization: token },
//   })
//     .then(
//       handleResponseSuccess,
//       handleResponseError,
//     );
// };

// export const postPointUpdate = (token: string, assignmentId: any) => {
//   console.log(`requesting point update for ${assignmentId} and token ${token}`);
//   return axios.post("api/points-v0/update", { assignmentId: assignmentId }, {
//     headers: { Authorization: token },
//   })
//     .then(
//       handleResponseSuccess,
//       handleResponseError,
//     );
// };
