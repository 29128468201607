import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { getLastProgrammingAssignmentSubmissionByAssignmentId } from '../../../http-actions/assignment-actions';
import {
    getAssignmentReviews,
    getPeerReviewFormByAssignmentUuid,
    getReviewAssignment,
    getSelfReviewFormByAssignmentUuid,
    submitReview,
} from '../../../http-actions/review-actions';
import { AuthContext } from '../../../providers/auth-provider';
import PeerReviewAssignment from './peer-review-assignment';
import PeerReviewViewer from './peer-review-viewer';

interface InputProps {
    assignmentUuid: string;
}

export default function ReviewAssignment(props: InputProps) {
    const [assignmentReviews, setAssignmentReviews] = useState([]);
    const [reviewAssignment, setReviewAssignment] = useState(null);
    const [assignmentSubmissions, setAssignmentSubmissions] = useState([]);
    const submittedReviews = Array.isArray(assignmentReviews)
        ? assignmentReviews.filter((sr: { review: Object }) => sr.review !== null)
        : [];

    const submittedSelfReviews = submittedReviews.filter((sr) => sr.is_self_review);
    const submittedPeerReviews = submittedReviews.filter((sr) => !sr.is_self_review);

    console.log('\n\ntest review assignment\n\n');
    console.log(reviewAssignment);
    console.log(submittedReviews.length);
    console.log(submittedReviews[0]);

    console.log('\n\ntest review assignment submissions\n\n');
    console.log(assignmentSubmissions);
    console.log(reviewAssignment);
    const selfReviewAvailable = reviewAssignment?.selfReview && assignmentSubmissions?.length > 0;
    console.log(selfReviewAvailable);

    const { state: authState } = useContext(AuthContext);

    useEffect(() => {
        if (authState.token) {
            fetchSubmittedReviews();
            void getReviewAssignment(authState.token, props.assignmentUuid).then(
                (reviewAssignment) => {
                    setReviewAssignment(reviewAssignment);
                },
            );
        }
    }, [authState]);

    useEffect(() => {
        if (authState.token && reviewAssignment?.assignmentId) {
            void getLastProgrammingAssignmentSubmissionByAssignmentId(
                authState.token,
                reviewAssignment.assignmentId,
            ).then((submissions) => {
                console.log('Retrieved the following submissions:');
                console.log(submissions);
                setAssignmentSubmissions(submissions);
            });
        }
    }, [reviewAssignment]);

    const fetchSubmittedReviews = () => {
        void getAssignmentReviews(authState.token, props.assignmentUuid).then((reviews) => {
            setAssignmentReviews(reviews);
        });
    };

    // TODO: refactor
    // TODO? get assignment title form assignment-api to show in title or use header field in review form?
    return (
        <>
            {/* Self review */}
            {selfReviewAvailable && (
                <>
                    <Typography id="self-review-header" variant="h2">
                        {'Self assessment'}
                    </Typography>
                    <PeerReviewAssignment
                        assignmentUuid={props.assignmentUuid}
                        getFormData={getSelfReviewFormByAssignmentUuid}
                        fetchSubmittedReviews={fetchSubmittedReviews}
                        isFirstFetch={assignmentReviews.length === 0}
                        nCompleted={submittedSelfReviews.length}
                        nAssignments={1}
                        download={'self'}
                    />
                </>
            )}
            {/* Peer review */}
            {submittedSelfReviews &&
                submittedSelfReviews.length > 0 &&
                reviewAssignment?.peerReviewCount > 0 && (
                    <>
                        <Typography id="peer-review-header" variant="h2">
                            {'Peer review'}
                        </Typography>
                        <PeerReviewAssignment
                            assignmentUuid={props.assignmentUuid}
                            getFormData={getPeerReviewFormByAssignmentUuid}
                            fetchSubmittedReviews={fetchSubmittedReviews}
                            isFirstFetch={assignmentReviews.length === 0}
                            nCompleted={submittedPeerReviews.length}
                            nAssignments={reviewAssignment?.peerReviewCount}
                            download={'peer'}
                        />
                    </>
                )}
            {/* Reviews from peers */}
            {/* {reviewAssignment?.peerReviewCount > 0 &&
                submittedPeerReviews?.length >= reviewAssignment?.peerReviewCount &&
                !props.doNotShowReviews && (
                    <>
                        <Typography id="reviews-from-peers-header" variant="h2">
                            {'Reviews from peers'}
                        </Typography>
                        <PeerReviewViewer
                            assignmentUuid={props.assignmentUuid}
                            nCompleted={submittedPeerReviews.length}
                            nAssignments={reviewAssignment?.peerReviewCount}
                        />
                    </>
                )} */}
        </>
    );
}
