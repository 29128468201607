import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getAssignmentPoolByPoolUuid } from '../../../http-actions/assignment-actions';
import { getRandomQuestionInPool } from '../../../http-actions/quiz-actions';
import { AuthContext } from '../../../providers/auth-provider';
import QuizDisplay from './quiz-display';
import AssignmentRatingDialog from '../assignment-rating-dialog';
import CustomButton from '../../button';
import localStorageUtil from '../../../util/local-storage-util';

import { useStore } from '@nanostores/react'
import { points, updatePointsAction, updatePoolPointsAction } from '../../../stores/points.ts';

interface PropTypesI {
    poolUuid: string;
}

const QuizCrowdSourced = (props: PropTypesI) => {
    if (!props.poolUuid) {
        return (
            <Paper
                sx={(theme) => ({
                    ...theme.assignmentHandout,
                })}
            >
                <Typography variant="h3" component="h2">
                    <Trans i18nKey="noPoolSet" />
                </Typography>
            </Paper>
        );
    }

    const poolUuid = props.poolUuid;

    const pointStore = useStore(points);
    
    const [question, setQuestion] = React.useState(undefined);
    const [assignmentPool, setAssignmentPool] = React.useState();
    const [noQuestionsAvailable, setNoQuestionsAvailable] = React.useState(false);
    const [noNewQuestionsAvailable, setNoNewQuestionsAvailable] = React.useState(false);
    const [currentAttempts, setCurrentAttempts] = React.useState(0);
    const debouncedRef = React.useRef(false);

    const [ratingDialogOpen, setRatingDialogOpen] = React.useState(false);

    const assignmentPoints = Array.isArray(pointStore?.pools[poolUuid])
        ? pointStore.pools[poolUuid]
        : [];

    const questionsAnswered = assignmentPoints
        .map((x) => x.points === x.maxPoints)
        .reduce((a, b) => a + b, 0);

    const [nextButtonDisabled, setNextButtonDisabled] = React.useState(true);
    const [answerButtonDisabled, setAnswerButtonDisabled] = React.useState(false);

    const { state: authState } = useContext(AuthContext);

    const { t } = useTranslation();

    const fetchQuestion = async (skippedQuestionIds: number[]) => {
        const response = await getRandomQuestionInPool(authState.token, poolUuid, skippedQuestionIds);

        if (response?.err === 'No questions available') {
            setNoQuestionsAvailable(true);
            return;
        }
        return response;
    };

    const loadQuestion = async () => {
        const skippedQuestions = localStorageUtil.getJson('skippedQuestions');
        const response = await fetchQuestion(skippedQuestions);
        if (!response?.data) return;
        setQuestionData(response.data, Boolean(response?.answered));
        setCurrentAttempts(0);
    };

    const handleSkipQuestion = () => {
        if (!question) return;

        const skippedQuestions = localStorageUtil.getJson('skippedQuestions');

        const updatedSkippedQuestions = skippedQuestions
            ? Array.from(new Set(skippedQuestions.concat(skippedQuestions, question.id)))
            : [question.id];

        localStorageUtil.setItem('skippedQuestions', JSON.stringify(updatedSkippedQuestions));

        setAnswerButtonDisabled(true);
        setNextButtonDisabled(false);
        if (!noNewQuestionsAvailable) {
            // TODO: Randomly skip showing the rating dialog
            setRatingDialogOpen(true); // No need to rate questions that you have already rated OR ignored rating
        }
        loadQuestion();
    };

    const setQuestionData = (data: any, answered: boolean) => {
        setQuestion(data);
        setNoNewQuestionsAvailable(answered);
        setNoQuestionsAvailable(false);
        setNextButtonDisabled(true);
        setAnswerButtonDisabled(false);
    };

    const debouncedLoadQuestion = () => {
        if (debouncedRef.current) return;

        debouncedRef.current = true;
        loadQuestion();

        setTimeout(() => {
            debouncedRef.current = false;
        }, 3000);
    };

    const updatePoints = () => {
        updatePointsAction(authState.token, poolUuid, question.assignmentId);
    };

    const onAnswerCorrect = () => {
        setAnswerButtonDisabled(true);
        setNextButtonDisabled(false);
        if (!noNewQuestionsAvailable) {
            // TODO: Randomly skip showing the rating dialog
            setRatingDialogOpen(true); // No need to rate questions that you have already rated OR ignored rating
        }
        updatePoints();
        setCurrentAttempts(0);
    };

    const onAnswerIncorrect = () => {
        setAnswerButtonDisabled(false);
        setNextButtonDisabled(true);
        setCurrentAttempts((prev) => prev + 1);
    };

    const handleCloseRatingDialog = () => {
        setRatingDialogOpen(false);
    };

    useEffect(() => {
        if (!poolUuid || !authState.token) {
            return;
        }
        console.log('Retrieving points for pool.');
        updatePoolPointsAction(authState.token, poolUuid).then(() => {
            void loadQuestion();
        });        
    }, [authState]);

    React.useEffect(() => {
        if (authState?.token && poolUuid) {
            getAssignmentPoolByPoolUuid(authState.token, poolUuid).then((response) => {
                if (!response?.err) {
                    setAssignmentPool(response);
                }
            });
        }
    }, [authState]);

    if (noQuestionsAvailable) {
        return (
            <Paper
                sx={(theme) => ({
                    ...theme.assignmentHandout,
                    display: 'flex',
                    alignItems: 'center',
                })}
            >
                <Typography mr={2} variant="h3" component="h2">
                    <Trans i18nKey="noAssignmentsAvailable" />
                </Typography>
                <CustomButton onClick={debouncedLoadQuestion} variant="contained" kind="fitech">
                    <Trans i18nKey="refreshButtonText" />
                </CustomButton>
            </Paper>
        );
    }

    if (!question) {
        return (
            <Paper
                sx={(theme) => ({
                    ...theme.assignmentHandout,
                })}
            >
                <Typography variant="h3" component="h2">
                    <Trans i18nKey="questionNotFoundOrLoading" />
                </Typography>
            </Paper>
        );
    }

    const poolHasQuestionsWithPoints = question && question.maxPoints && true;
    const poolPointsServer = poolHasQuestionsWithPoints
        ? assignmentPoints.map((x) => x.points).reduce((a, b) => a + b, 0)
        : 0;
    const poolMaxPoints = assignmentPool?.maxPoints;
    const poolPoints =
        Number.isInteger(poolMaxPoints) &&
        Number.isInteger(poolPointsServer) &&
        Math.min(poolMaxPoints, poolPointsServer);

    const nCompleted = questionsAnswered;
    // const nAssignments = poolHasQuestionsWithPoints && poolMaxPoints / question.maxPoints;
    const nAssignments = '-';

    // console.log('QQ');
    // console.log(nAssignments);
    console.log('QQ', question, poolHasQuestionsWithPoints);
    console.log("Pool max points: " + poolMaxPoints);
    console.log("Pool points: " + poolPoints);


    const quizDisplayProps = {
        poolUuid: props.poolUuid,
        question,
        answerButtonDisabled,
        nextButtonDisabled,
        poolPoints,
        poolMaxPoints,
        loadNewQuestion: loadQuestion,
        onAnswerCorrect,
        onAnswerIncorrect,
        nAssignments,
        nCompleted,
        textAsMarkdown: true,
        noNewQuestionsAvailable,
        skippable: !noNewQuestionsAvailable && currentAttempts >= 2,
        handleSkipQuestion,
        showAssignmentHeaderInQuizDisplay: true
    };

    return (
        <>
            <AssignmentRatingDialog
                assignmentId={question?.assignmentId}
                open={ratingDialogOpen}
                handleClose={handleCloseRatingDialog}
            />
            <QuizDisplay {...quizDisplayProps} />
        </>
    );
};

export default QuizCrowdSourced;
