import React, { useEffect, useRef, useState } from 'react';
/*
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import * as Fi from 'blockly/msg/fi';
import BlocklyDart from 'blockly/dart';
import defaultToolbox from './toolbox/defaultToolbox';
import { Workspace } from 'blockly';
import { ToolboxDefinition } from 'utils/toolbox';
*/


const BlocklyBlock = (props) => {
    return (
        <div><p>Blockly not presently in use.</p></div>
    );
};


export default BlocklyBlock;

/*
Blockly.setLocale(Fi);

interface HookPropTypesI {
    ref: any;
    toolbox: ToolboxDefinition;
    onChange?: Function;
}
const useBlocklyWorkspace = (props: HookPropTypesI) => {
    const { ref, toolbox, onChange } = props;
    const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);
    const toolboxRef = useRef(toolbox);

    useEffect(() => {
        toolboxRef.current = toolbox;
        if (toolbox && workspace) {
            workspace.updateToolbox(toolbox);
        }
    }, [toolbox, workspace]);

    useEffect(() => {
        const workspace = Blockly.inject(ref.current, { toolbox }); //: toolboxRef.current });
        setWorkspace(workspace);

        const onChangeListener = onChange ? () => onChange(workspace) : null

        onChangeListener && workspace.addChangeListener(onChangeListener);
        return () => {
            onChangeListener && workspace.removeChangeListener(onChangeListener);
            workspace.dispose();
        };
    }, [ref]);
};

interface PropTypesI {
    id?: string;
}

const BlocklyBlock = (props: PropTypesI) => {
    const blocklyRef = useRef(null);
    const onWorkspaceUpdate = (workspace: Workspace) => {
        const code = BlocklyDart.workspaceToCode(workspace);
        console.log(code);
    };

    const workspace = useBlocklyWorkspace({
        ref: blocklyRef,
        // onChange: onWorkspaceUpdate,
        toolbox: defaultToolbox,
    });

    return (
        <div>
            <div ref={blocklyRef} style={{ height: '480px', width: '100%' }} />
        </div>
    );
};

export default BlocklyBlock;
*/