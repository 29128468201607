import React, { useEffect, useState } from "react";
// import AceEditor from 'react-ace';
const AceEditor = typeof window !== "undefined"
  ? require("react-ace").default
  : null;
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { School } from "@mui/icons-material";
import DialogWrapper from "../../dialog-wrapper";
import { Trans, useTranslation } from "react-i18next";

const codeViewStyle = {
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  MsUserSelect: "none",
  userSelect: "none",
  backgroundColor: "rgb(255, 255, 255)",
  borderRadius: "5px",
  border: "2px solid #621BFB",
  padding: "15px",
};

const CodeView = (props) => {
  return (
    <pre unselectable="on" style={codeViewStyle}>
            {props.code}
    </pre>
  );
};

const CodeRecallCodeView = (props) => {
  const { t } = useTranslation();

  if (!props.secondsToShow) {
    return (
      <Typography>
        The secondsToShow property is missing.
      </Typography>
    );
  }

  if (!props.code) {
    return (
      <Typography>
        The code property is missing.
      </Typography>
    );
  }

  const [showCode, setShowCode] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(props.secondsToShow);

  const displayCode = () => {
    setShowCode(true);
  };

  if (!showCode) {
    return (
      <Button variant="contained" color="primary" onClick={displayCode}>
        <Trans i18nKey="showMeTheCode" />
      </Button>
    );
  } else {
    if (secondsRemaining > 0) {
      setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);

      const content = (
        <>
          <Typography>
            Try to remember as much as possible from the following code.
          </Typography>
          <CodeView code={props.code} />
          <Typography>
            <Trans
              i18nKey="secondsRemaining"
              values={{ seconds: `${secondsRemaining}` }}
            />
          </Typography>
        </>
      );

      return (
        <DialogWrapper
          content={content}
          isOpen={showCode}
          disableDefaultClose={true}
        >
        </DialogWrapper>
      );
    } else {
      setShowCode(false);
    }
  }
};

export default CodeRecallCodeView;
