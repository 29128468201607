/* eslint-disable */

import React, { useContext, useEffect } from "react";

import {
  getFeedbackStatus,
  postFeedback,
} from "../../http-actions/feedback-actions";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";

import { AuthContext } from "../../providers/auth-provider";

import { Trans, useTranslation } from "react-i18next";

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
CHANGE SUBMIT BUTTON TO FITECH STYLE
USE THEME DEFINITIONS
*/

const sliderMarks = [...Array(11).keys()].map((i) => {
  return { value: i, label: i.toString() };
});

const sxSliderStyle = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  },
};

const sxSliderStyleExp = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="4"]': {
      transform: "translateX(-100%)",
    },
  },
};

const SliderQuestion = (props) => {
  const { t } = useTranslation();

  const optionMarks = [
    {
      value: 1,
      label: t("stronglyDisagree"),
    },
    {
      value: 7,
      label: t("stronglyAgree"),
    },
  ];

  return (
    <>
      <Typography
        id={props.id}
        variant="body2"
        gutterBottom
        sx={{ marginTop: "1em" }}
      >
        <Trans i18nKey={props.id} />
      </Typography>
      <Slider
        size="small"
        sx={sxSliderStyle}
        name={props.id}
        defaultValue={4}
        min={1}
        max={7}
        aria-labelledby={props.id}
        step={1}
        valueLabelDisplay="auto"
        track={false}
        marks={optionMarks}
        onChange={props.handleChange(props.id)}
      />
    </>
  );
};

const valueText = (value) => {
  return `${value}`;
};

const Survey = (props) => {
  const { t } = useTranslation();

  const { state: authState } = useContext(AuthContext);
  const [lastAnsweredAt, setLastAnsweredAt] = React.useState();
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [showForm, setShowForm] = React.useState(true);
  const [location, setLocation] = React.useState("undefined");

  const [feedback, setFeedback] = React.useState<State>({
    iFoundThePrecedingLessonsInteresting: null,
    iFeelThatILearnedAboutProgrammingConceptsFromTheseLessons: null,
    iAlreadyKnewBeforehandHowToDoGraphicalProgrammingSimilarToWhatWasTaught:
      null,
    iAlreadyKnewBeforehandAllGeneralProgrammingContent: null,
    programmingWithGraphicsIsFun: null,
    iLikeProgrammingWithGraphicsMoreThanTextbasedProgrammingWeHaveDoneInY1:
      null,
    iWouldLikeToLearnMoreAboutProgrammingWithGraphics: null,
    feedbackOnActivities: null,
  });

  const programSizeInLines = [
    {
      value: 0,
      label: t("0"),
    },
    {
      value: 1,
      label: `${t("under")} 50`,
    },
    {
      value: 2,
      label: "50-500",
    },
    {
      value: 3,
      label: "501-5000",
    },
    {
      value: 4,
      label: `${t("over")} 5000`,
    },
  ];

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const fb = { ...feedback, [prop]: event.target.value };
      setFeedback(fb);

      if (timeoutId) {
        try {
          clearTimeout(timeoutId);
        } catch (e) {
          // console.log(e);
        }
      }

      const tId = setTimeout(() => {
        postFeedback(authState.token, location, fb);
      }, 4000);
      setTimeoutId(tId);
    };

  useEffect(() => {
    setShowForm(!Boolean(lastAnsweredAt));
  }, [lastAnsweredAt]);

  const submitFeedback = () => {
    postFeedback(authState.token, location, feedback);
    setShowForm(false);

    setLastAnsweredAt(new Date());
  };

  const getLastFeedbackTime = async (loc) => {
    const status = await getFeedbackStatus(authState.token, loc);
    if (
      status.last_feedback && status.last_feedback[0] &&
      status.last_feedback[0].max
    ) {
      setLastAnsweredAt(new Date(status.last_feedback[0].max));
    }
  };

  useEffect(() => {
    if (!authState.token) {
      return;
    }

    let path = window.location.pathname.replace(/\/+$/, "");
    path = path[0] == "/" ? path.substr(1) : path;
    const pathArray = path.split("/");

    const loc = pathArray[0] + "-" + pathArray[1] + "-" + pathArray[2];
    setLocation(loc);
    getLastFeedbackTime(loc);
  }, [authState]);

  return (
    <>
      {showForm && (
        <>
          <SliderQuestion
            id="iFoundThePrecedingLessonsInteresting"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="iFeelThatILearnedAboutProgrammingConceptsFromTheseLessons"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="iAlreadyKnewBeforehandHowToDoGraphicalProgrammingSimilarToWhatWasTaught"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="iAlreadyKnewBeforehandAllGeneralProgrammingContent"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="programmingWithGraphicsIsFun"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="iLikeProgrammingWithGraphicsMoreThanTextbasedProgrammingWeHaveDoneInY1"
            handleChange={handleChange}
          />
          <SliderQuestion
            id="iWouldLikeToLearnMoreAboutProgrammingWithGraphics"
            handleChange={handleChange}
          />
          <Typography
            id="feedbackOnActivities"
            variant="body2"
            gutterBottom
            sx={{ marginTop: "1em" }}
          >
            <Trans i18nKey="feedbackOnActivitiesQuestion" />
          </Typography>
          <TextField
            onChange={handleChange("feedbackOnActivities")}
            name="feedbackOnActivities"
            multiline
            rows={4}
            aria-labelledby="feedbackOnActivities"
            defaultValue=""
            variant="outlined"
            fullWidth
          />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={submitFeedback}
            sx={{ marginTop: "1em" }}
          >
            <Trans i18nKey="sendSurvey" />
          </Button>
        </>
      )}
      {lastAnsweredAt && !showForm && (
        <>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ mt: 1 }}
            fontStyle="italic"
          >
            <Trans
              i18nKey="surveySentThanks"
              values={{ lastAnsweredAt: lastAnsweredAt.toLocaleString() }}
            />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowForm(true)}
            sx={{ mt: 1 }}
          >
            <Trans i18nKey="fillSurveyAgain" />
          </Button>
        </>
      )}
    </>
  );
};

const PytamaroPostSurveyForm = (props) => {
  return (
    <Paper sx={{ my: 2, p: 2 }}>
      <Survey {...props} />
    </Paper>
  );
};

export default PytamaroPostSurveyForm;
