import React from "react";
import RandomizedExperiment from "./randomized-experiment";
import RandomizedExperimentGroup from "./randomized-experiment-group";
import { useStore } from '@nanostores/react'
import { points } from '../../../stores/points.ts';

const RandomizedFITechExperimentInitiator = (props) => {
  const pointStore = useStore(points);

  const getPathArray = () => {
    let path = typeof window !== "undefined"
      ? window.location.pathname.replace(/\/+$/, "")
      : "";
    path = path[0] == "/" ? path.substr(1) : path;
    var pathArray = path.split("/");

    return pathArray;
  };

  const getCurrentModule = () => {
    const pathArray = getPathArray();
    return pathArray[1];
  };

  const fitechModules = [
    "data-and-information",
    "introduction-to-programming",
    "internet-and-browser-applications",
    "mobile-application-development",
  ];

  const moduleName = getCurrentModule();
  if (!fitechModules.includes(moduleName)) {
    return null;
  }

  console.log("---RandomizedExperimentInitiator---");

  if (
    !pointStore?.modulePoints ||
    Object.keys(pointStore?.modulePoints ?? {}).length === 0
  ) {
    return null;
  }
  const modulePointPercentages: number[] = fitechModules.map((module) => {
    const _module = pointStore.modulePoints[module];
    return (_module.points / _module.maxPoints) * 100;
  });

  let gamificationExperimentInitiator = <></>;
  if (
    moduleName === "introduction-to-programming" && pointStore.modulePoints &&
      pointStore.modulePoints["introduction-to-programming"] &&
      pointStore.modulePoints["introduction-to-programming"].points &&
      pointStore.modulePoints["introduction-to-programming"].points > 0 &&
      pointStore.modulePoints["introduction-to-programming"].points < 250
  ) {
    gamificationExperimentInitiator = (
      <RandomizedExperiment name="2023-levels-gamification">
        <RandomizedExperimentGroup
          id="no-gamification-levels"
          reactType="RandomizedExperimentGroup"
        >
          <></>
        </RandomizedExperimentGroup>
        <RandomizedExperimentGroup
          id="yes-gamification-levels"
          reactType="RandomizedExperimentGroup"
        >
          <></>
        </RandomizedExperimentGroup>
      </RandomizedExperiment>
    );
  }

  // Leave out users who have completed a course from the experiment
  if (Math.max(...modulePointPercentages) >= 89) {
    return null;
  }

  return (
    <>
      {gamificationExperimentInitiator}
      {Math.max(...modulePointPercentages) >= 75 && ( // Leave out users who never get past 75% point mark (of any fitech course) from the experiment
        <RandomizedExperiment name="2022-applying-for-credits">
          <RandomizedExperimentGroup
            id="no-information-or-notification"
            reactType="RandomizedExperimentGroup"
          >
            <></>
          </RandomizedExperimentGroup>
          <RandomizedExperimentGroup
            id="information"
            reactType="RandomizedExperimentGroup"
          >
            <></>
          </RandomizedExperimentGroup>
          <RandomizedExperimentGroup
            id="information-and-notification"
            reactType="RandomizedExperimentGroup"
          >
            <></>
          </RandomizedExperimentGroup>
          <RandomizedExperimentGroup
            id="notification"
            reactType="RandomizedExperimentGroup"
          >
            <></>
          </RandomizedExperimentGroup>
        </RandomizedExperiment>
      )}
    </>
  );
};

export default RandomizedFITechExperimentInitiator;
