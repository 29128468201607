import React, { useContext, useEffect } from "react";
import { AuthContext } from "../providers/auth-provider";
import { UserContext } from '../providers/user-provider';
import { getUserData } from '../http-actions/user-actions';
import { postAnalyticsData } from "../http-actions/analytics-actions";

const UidLink = (props) => {
  const { state: authState } = useContext(AuthContext);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const user = userState.user;

  const getUserDetails = async () => {
      console.log('Retrieving user details');
      if (!authState.token) {
          console.log('No token in authstate...');
          return;
      }

      await getUserData(authState.token).then((data) =>
          userDispatch({ type: 'UPDATE_USER', user: data }),
      );
  };

    if (!authState || !user || !authState.email) {
        return (
            <p>Unable to show the link. Have you logged in?</p>
        );
    }

  const logAiChatEvent = async (type, path) => {
    console.log("Log ai chat event " + type + " at path " + path);
    postAnalyticsData(authState.token, "open-external-link", {
      path: path,
      type: type,
      link: props.link,
    });
  };

  useEffect(() => {
      console.log('authstate changed, retrieving user details..');
      void getUserDetails();
  }, [authState]);

  return (
    <>
      <a href={`${props.link}?link=${user.userUuid}`} target="_blank">{props.text}</a>
    </>
  );
};

export default UidLink;
