import React, { useContext, useEffect } from "react";
import { AuthContext } from "../providers/auth-provider";
import { UserContext } from "../providers/user-provider";
import { getUserData } from "../http-actions/user-actions";
import { Typography } from '@mui/material';

const UserId = (props) => {
  const { state: authState } = useContext(AuthContext);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const user = userState.user;

  const getUserDetails = async () => {
    console.log("Retrieving user details");
    if (!authState.token) {
      console.log("No token in authstate...");
      return;
    }

    await getUserData(authState.token).then((data) =>
      userDispatch({ type: "UPDATE_USER", user: data })
    );
  };

  if (!authState || !userState || !user || !user.id) {
    return (
      <Typography>
        User id not available.
      </Typography>
    );
  }

  useEffect(() => {
    console.log("authstate changed, retrieving user details..");
    void getUserDetails();
  }, [authState]);

  return (
    <Typography>
      User id: {user.id}
    </Typography>
  );
};

export default UserId;
