import { Container, Typography } from '@mui/material';
import React from 'react';

const SVGImageWrapper = ({ children, ...props }) => {
    console.log(props);
    return (
        <Container component={'div'} sx={{
            margin: theme => theme.spacing(4, 0),
            '& > img': {
                display: 'block',
                margin: 'auto',
                maxWidth: '100%',
            },
        }}>
            {children}
            <Typography variant="body2" sx={{
                maxWidth: '80%',
                margin: 'auto',
                fontStyle: 'italic'
            }}>{props.title}</Typography>
        </Container>
    );
};

export default SVGImageWrapper;
