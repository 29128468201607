import { Box } from '@mui/material';
import { navigate } from 'gatsby';
import { Trans } from 'react-i18next';
import React, { useContext } from 'react';

import { postAnalyticsData } from '../http-actions/analytics-actions';
import { AuthContext } from '../providers/auth-provider';
import { NavigationInfoContext } from '../providers/navigation-info-provider';
import Button from './button';
import { getPrevAndNextPart } from '../util/prev-next-util';

const PrevNextNav = (props) => {
    const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "unknown";

    if (pathname.includes("experiments") && pathname.includes("code-recall")) {
        return null;
    }

    const { state: authState } = useContext(AuthContext);

    const {
        state: { currentNode, isMaterialPage, moduleMap },
    } = React.useContext(NavigationInfoContext);

    const logPageNavigation = (path, direction) => {
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            direction: direction,
            type: props.bottom ? 'end-of-page-navigation' : 'start-of-page-navigation',
        });
    };

    const [prevAndNext, setPrevAndNext] = React.useState([undefined, undefined]);

    React.useEffect(() => {
        const showBottomNav = isMaterialPage && currentNode && moduleMap;
        const newPrevNext = showBottomNav
            ? getPrevAndNextPart(currentNode, moduleMap, props?.pageContext)
            : [undefined, undefined];
        setPrevAndNext(newPrevNext);
    }, [currentNode]);

    const [prevPart, nextPart] = prevAndNext;

    return (
        <Box
            component="nav"
            id="navigaatio-edellinen-tai-seuraava-osa"
            sx={{
                padding: (theme) => theme.spacing(2, 0),
                display: 'grid',
                columnGap: (theme) => theme.spacing(2),
                gridTemplateColumns: '1fr 1fr',
            }}
        >
            {prevPart && prevPart.slug && prevPart.published && (
                <Button
                    color="inherit"
                    kind="fitech"
                    elevation={0}
                    fullWidth
                    sx={{
                        maxWidth: '180px',
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        gridColumn: '1 / 2',
                        justifySelf: 'start',
                        zIndex: 20,
                    }}
                    // variant="contained"
                    onClick={() => {
                        logPageNavigation(prevPart.slug, 'previous');
                        navigate(prevPart.slug);
                    }}
                >
                    <Trans i18nKey="prevPart" />
                </Button>
            )}
            {nextPart && nextPart.slug && nextPart.published && (
                <Button
                    color="inherit"
                    kind="fitech"
                    sx={{
                        gridColumn: '2 / 3',
                        justifySelf: 'end',
                        maxWidth: '180px',
                        color: 'fitech.contrastText',
                        backgroundColor: 'fitech.main',
                        transition: (theme) =>
                            theme.transitions.create(['color', 'background-color']),
                        '&:hover': {
                            color: 'common.black',
                        },
                        zIndex: 20,
                    }}
                    elevation={0}
                    fullWidth
                    onClick={() => {
                        logPageNavigation(nextPart.slug, 'next');
                        navigate(nextPart.slug);
                    }}
                >
                    <Trans i18nKey="nextPart" />
                </Button>
            )}
        </Box>
    );
};

export default PrevNextNav;
