import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { getPersonalizedContents } from '../../http-actions/personalized-content-actions';
import { AuthContext } from '../../providers/auth-provider';
import MarkdownPreview from '../markdown-preview';
import PersonalizedContentPart from './personalized-content-part';
import { find, findIndex } from 'lodash';

interface PropTypes {
    targetLanguage: string;
    uuid: string;
    defaultTopic?: string;
    tabbed?: boolean;
}

const PersonalizedContent = (props: PropTypes) => {
    const { uuid, targetLanguage, tabbed } = props;
    const defaultTopic = props.defaultTopic ?? 'generic';

    const [personalizedContents, setPersonalizedContents] = useState(null);
    const [language, setLanguage] = useState(targetLanguage);
    const [topic, setTopic] = useState(defaultTopic);
    const [openTabIndex, setOpenTabIndex] = React.useState(0);
    const personalizedContentIndex = tabbed
        ? openTabIndex
        : findIndex(personalizedContents, { language, topic });
    const personalizedContent =
        personalizedContents && personalizedContents[personalizedContentIndex];

    const handleChange = (event, newValue) => {
        setOpenTabIndex(newValue);
        setLanguage(personalizedContents[openTabIndex]?.language);
    };

    const { state: authState } = useContext(AuthContext);

    useEffect(() => {
        authState.token &&
            getPersonalizedContents(authState.token, uuid).then((contents) =>
                setPersonalizedContents(contents),
            );
    }, [authState.token]);

    useEffect(() => {
        personalizedContents?.length > 0 && setOpenTabIndex(findIndex(personalizedContents, { language, topic }));
    }, [personalizedContents]);

    if (tabbed) {
        return (
            <>
                <Tabs
                    value={openTabIndex}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="ace editor tabs"
                >
                    {personalizedContents?.map((contents) => (
                        <Tab
                            key={`ace-tab-${contents.uuid}-${contents.language}`}
                            label={contents.language}
                            // className={classes.aceEditorTab}
                        />
                    ))}
                </Tabs>
                {personalizedContent?.parts.map((part) => (
                    <PersonalizedContentPart key={part.partOrder} language={language} part={part} />
                ))}
            </>
        );
    }
    return (
        <>
            {personalizedContent?.parts.map((part) => (
                <PersonalizedContentPart key={part.partOrder} language={language} part={part} />
            ))}
        </>
    );
};

export default PersonalizedContent;
