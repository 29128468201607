/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";

import { Trans, useTranslation } from "react-i18next";

const Label = (props) => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        fontSize: "1rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
    >
      {props.children}
    </Typography>
  );
};

const sxSliderStyle = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="1"]': {
      transform: "translateX(-100%)",
    },
  },
};

const sxSliderStyleExp = {
  ".MuiSlider-markLabel": {
    '&[data-index="0"]': {
      transform: "translateX(0%)",
    },
    '&[data-index="4"]': {
      transform: "translateX(-100%)",
    },
  },
};

const PostExerciseSurveyForm = (props) => {
  const [data, setData] = React.useState({
    descriptionClear: 0,
    matchedSelectedTheme: 0,
    matchedSelectedConcept: 0,
    matchedSelectedDifficulty: 0,
  });

  const handleChange = (key, newValue: number | number[]) => {
    const updated = {
      ...data,
      [key]: newValue,
    };

    setData(updated);
    props.updateData(updated);
  };

  const likertScaleAgreement = [
    {
      value: 1,
      label: "Strongly disagree",
    },
    {
      value: 7,
      label: "Strongly agree",
    },
  ];

  const valueText = (value) => {
    return `${value}`;
  };

  return (
    <>
      <Typography
        gutterBottom
        sx={{ pt: 2 }}
      >
        Congrats on successfully completing the exercise, please provide us
        feedback!
      </Typography>
      <Label component="legend">
        Please indicate how much you agree with each of the following
        statements, or how true it is about you. Use the scale from 1 to 7,
        where 1 is 'Strongly disagree' and 7 is 'Strongly agree'.
      </Label>
      <Typography gutterBottom id="descriptionClear" sx={{ pt: 2 }}>
        The exercise description was clear.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="descriptionClear"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) => handleChange("descriptionClear", v)}
      />
      <Typography gutterBottom id="matchedSelectedTheme" sx={{ pt: 2 }}>
        The exercise description matched the selected theme.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="matchedSelectedTheme"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) => handleChange("matchedSelectedTheme", v)}
      />
      <Typography
        gutterBottom
        id="matchedSelectedConcept"
        sx={{ pt: 2 }}
      >
        The exercise description matched the selected concept.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="matchedSelectedConcept"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) => handleChange("matchedSelectedConcept", v)}
      />
      <Typography
        gutterBottom
        id="matchedSelectedDifficulty"
        sx={{ pt: 2 }}
      >
        The exercise difficulty matched the selected difficulty.
      </Typography>
      <Slider
        sx={sxSliderStyle}
        getAriaValueText={valueText}
        min={1}
        max={7}
        aria-labelledby="matchedSelectedDifficulty"
        step={1}
        defaultValue={4}
        valueLabelDisplay="auto"
        track={false}
        marks={likertScaleAgreement}
        onChangeCommitted={(e, v) =>
          handleChange("matchedSelectedDifficulty", v)}
      />
    </>
  );
};

const PostExerciseSurvey = (props) => {
  const [data, setData] = React.useState({
    descriptionClear: 0,
    matchedSelectedTheme: 0,
    matchedSelectedConcept: 0,
    matchedSelectedDifficulty: 0,
  });

  const updateData = (data) => {
    setData(data);
  };
  
  const skip = () => {
    props.submitFeedback({ ...data, selection: "skip", type: "post-exercise-survey" });
  };

  const submitFeedback = () => {
    props.submitFeedback({ ...data, selection: "submit", type: "post-exercise-survey" });
  };

  return (
    <>
      <PostExerciseSurveyForm updateData={updateData} />
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={submitFeedback}
        >
          Submit feedback
        </Button>
        <Button
          variant="outlined"
          onClick={skip}
          sx={{ ml: 2 }}
        >
          Skip
        </Button>
      </Box>
    </>
  );
};

export default PostExerciseSurvey;
