import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import AcosContent from '../components/acos-content';
import CodePuzzle from '../components/assignment/code-puzzles/code-puzzle';
import InfiniteProgrammingAssignment from '../components/assignment/programming/infinite-programming-assignment';
import ProgrammingAssignmentPool from '../components/assignment/programming/programming-assignment-pool';
import Quiz from '../components/assignment/quiz/quiz';
import AceEditor from '../components/code-editor/ace';
import AceEditorTabs from '../components/code-editor/ace-tabs';
import Dartpad from '../components/code-editor/dartpad';
// New components need to be added here
import { FileCopy as FileCopyIcon, OpenInNew as OpenInNewIcon, Preview as ShowSolutionIcon, ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import BlocklyBlock from '../components/blockly/blockly-block';
import CodeExplanation from '../components/code-explanation';
import LLMCodeExplanation from '../components/code-explanations/llm-code-explanation';
import CreditApplicationGuidelines from '../components/credits/credit-application-guidelines';
import UserUuidCode from '../components/credits/user-uuid-code';
import CodeRecallExperiment from '../components/experiments/code-recall/code-recall-experiment';
import Experiment from '../components/experiments/experiment';
import RandomizedExperiment from '../components/experiments/randomized-experiments/randomized-experiment';
import RandomizedExperimentGroup from '../components/experiments/randomized-experiments/randomized-experiment-group';
import RandomizedFITechExperimentInitiator from '../components/experiments/randomized-experiments/randomized-fitech-experiment-initiator';
import ExtraInfoBox from '../components/extra-info-box';
import FeedbackForm from '../components/feedback/feedback-form';
import ResetPasswordForm from '../components/forms/reset-password-form';
import Mermaid from '../components/graphs/mermaid';
import GuessingGame from '../components/guessing-game';
import HtmlBrowserView from '../components/html-browser-view';
import HtmlTreeView from '../components/html-tree-view';
import InfoBox from '../components/info-box';
import InformationContentShuffler from '../components/information/information-content-shuffler';
import InformationIntegerToBinaryToChar from '../components/information/information-int-to-binary-to-char';
import InformationStringBinaryTable from '../components/information/information-string-binary-table';
import LearningObjectives from '../components/learning-objectives';
import UnlimitedReviewAssignment from '../components/peer-review/admin/unlimited-review-assignment';
import PeerReviewViewer from '../components/peer-review/student/peer-review-viewer';
import ReviewAssignment from '../components/peer-review/student/review-assignment';
import PersonalizedContent from '../components/personalized-content/personalized-content';
import OverallProgressPercentage from '../components/points/overall-progress-percentage';
import RestrictedByCourseProgress from '../components/points/restricted-by-course-progress';
import PrevNextNav from '../components/prev-next-nav';
import UserProfileOptions from '../components/profile/user-profile-options';
import ProgramOutput from '../components/program-output';
import RestrictedByRole from '../components/restricted-by-role';
import PlatformStatistics from '../components/statistics/platform-statistics';
import SVGImageWrapper from '../components/svg-image-wrapper';
import { H1, H2, H3, H4, H5 } from '../components/text-components';
import Todo from '../components/todo';
import Video from '../components/video';
import Dialogue from '../components/dialogue/dialogue';
import RockPaperScissors from '../components/rock-paper-scissors';

import LazyLoad from 'react-lazy-load';
import PeerQuiz from '../components/assignment/peer-quiz/peer-quiz';
import QuizCrowdSourced from '../components/assignment/quiz/quiz-crowd-sourced';
import InlineFeedbackContainer from '../components/inline-feedback/inline-feedback-container';

import LLMWithSEFeedbackForm from '../components/feedback/llm-with-se-feedback-form';
import AIFFeedbackFeedbackForm from '../components/feedback/aif-feedback-feedback-form';

import PytamaroBackgroundSurveyForm from '../components/feedback/pytamaro-background-survey-form';
import PytamaroPostSurveyForm from '../components/feedback/pytamaro-post-opinion-survey';
import CodePlayback from '../components/code-playback/code-playback';

import UidLink from '../components/uid-link';
import PokeAPIFeedbackForm from '../components/feedback/pokeapi-feedback-form';
import CodeRecallCodeView from "../components/experiments/code-recall/code-recall-code-view-with-timeout";

import UserId from '../components/user-id';

import NewFitechApiEval from "../components/new-fitech-api-eval";

import ThemedAssignmentPool from "../components/assignment/ai-generated-practice-exercises/themed-assignment-pool.tsx";

const LazyAceEditor = (props: any) => (
    <LazyLoad>
        <AceEditor {...props} />
    </LazyLoad>
);

const shortcodes = {
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    BlocklyBlock,
    ProgrammingAssignmentPool,
    InfiniteProgrammingAssignment,
    ProgramOutput,
    Todo,
    InformationContentShuffler,
    InformationStringBinaryTable,
    InformationIntegerToBinaryToChar,
    HtmlBrowserView,
    HtmlTreeView,
    LearningObjectives,
    Dartpad,
    AceEditor: LazyAceEditor,
    AceEditorTabs,
    PersonalizedContent,
    InfoBox,
    ExtraInfoBox,
    Quiz,
    CodeExplanation,
    LLMCodeExplanation,
    Video,
    SVGImageWrapper,
    CreditApplicationGuidelines,
    UserUuidCode,
    UserProfileOptions,
    FeedbackForm,
    GuessingGame,
    AcosContent,
    ResetPasswordForm,
    Experiment,
    CodePuzzle,
    CodeRecallExperiment,
    RandomizedExperiment,
    RandomizedExperimentGroup,
    RestrictedByCourseProgress,
    OverallProgressPercentage,
    ReviewAssignment,
    PlatformStatistics,
    UnlimitedReviewAssignment,
    RestrictedByRole,
    PeerReviewViewer,
    Mermaid,
    OpenInNewIcon,
    ShowSolutionIcon,
    ArrowRightIcon,
    FileCopyIcon,
    PeerQuiz,
    QuizCrowdSourced,
    Dialogue,
    RockPaperScissors,
    LLMWithSEFeedbackForm,
    PytamaroBackgroundSurveyForm,
    PytamaroPostSurveyForm,
    CodePlayback,
    AIFFeedbackFeedbackForm,
    UidLink,
    PokeAPIFeedbackForm,
    CodeRecallCodeView,
    UserId,
    NewFitechApiEval,
    ThemedAssignmentPool
};

export default function PageTemplate({ data, ...rest }) {
    console.log('PAGE_TEMPLATE_REST', rest);

    return (
        <>
            <RandomizedFITechExperimentInitiator />
            <main className="material-page" id="skip-nav-target">
                <H1>{data.mdx.frontmatter.title}</H1>
                <PrevNextNav pageContext={rest.pageContext} />
                <InlineFeedbackContainer disabled={data.mdx.frontmatter?.hideInlineFeedback}>
                    <MDXProvider components={shortcodes}>
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </MDXProvider>
                </InlineFeedbackContainer>
                <PrevNextNav bottom pageContext={rest.pageContext} />
            </main>
        </>
    );
}

export const pageQuery = graphql`
    query MaterialContentPageQuery($id: String, $category: String) {
        mdx(id: { eq: $id }) {
            id
            body
            fields {
                collection
            }
            frontmatter {
                title
                published
            }
        }
        allMdx(
            filter: {
                fields: { category: { eq: $category }, pageType: { eq: "material-content-page" } }
            }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    fields {
                        category
                        slug
                    }
                    frontmatter {
                        title
                        published
                    }
                }
            }
        }
    }
`;
