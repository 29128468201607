/* eslint-disable */

import React, { useContext, useEffect } from 'react';

import { getFeedbackStatus, postFeedback } from '../../http-actions/feedback-actions';

import { Button, TextField, Typography, Paper } from '@mui/material';

import Slider from '@mui/material/Slider';
import { AuthContext } from '../../providers/auth-provider';

import { Trans, useTranslation } from 'react-i18next';


                        /*
The PokéAPI platform was useful for learning about the Pokémon API. - usefulForLearning
The user interface of the PokéAPI platform was user-friendly and easy to navigate. - userInterfaceFriendlyAndEasyToNavigate
The PokéAPI platform helped me in solving the Pokémon API -related course assignments.  helpedSolvingCourseAssignments
The PokéAPI platform helped me comprehend the Pokémon API. -helpedMeComprehendApi
Platforms like the PokéAPI platform can help with API comprehension. - canHelpWithApiComprehension
I would prefer studying API documentations directly without the help of tools like the PokéAPI platform. - wouldPreferStudyingApiDirectly
    */ 

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
CHANGE SUBMIT BUTTON TO FITECH STYLE
USE THEME DEFINITIONS
*/

const sliderMarks = [...Array(11).keys()].map((i) => {
    return { value: i, label: i.toString() };
});

const sxSliderStyle = {
    '.MuiSlider-markLabel': {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    },
};

const SliderQuestion = (props) => {
    const { t } = useTranslation();

    const optionMarks = [
        {
            value: 1,
            label: t('stronglyDisagree'),
        },
        {
            value: 7,
            label: t('stronglyAgree'),
        },
    ];

    return (
        <>
            <Typography id={props.id} variant="body2" gutterBottom sx={{ marginTop: '1em' }}>
                {props.text}
            </Typography>
            <Slider
                size="small"
                sx={sxSliderStyle}
                name={props.id}
                defaultValue={4}
                min={1}
                max={7}
                aria-labelledby={props.id}
                step={1}
                valueLabelDisplay="auto"
                track={false}
                marks={optionMarks}
                onChange={props.handleChange(props.id)}
            />
        </>
    );
};

const Feedback = (props) => {
    const { state: authState } = useContext(AuthContext);
    const [lastAnsweredAt, setLastAnsweredAt] = React.useState();
    const [showForm, setShowForm] = React.useState(true);
    const [location, setLocation] = React.useState('undefined');

    const [feedback, setFeedback] = React.useState<State>({
        usefulForLearning: null,
        userInterfaceFriendlyAndEasyToNavigate: null,
        helpedSolvingCourseAssignments: null,
        helpedMeComprehendApi: null,
        canHelpWithApiComprehension: null,
        wouldPreferStudyingApiDirectly: null,
        improvementSuggestions: null,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback({ ...feedback, [prop]: event.target.value });
    };

    useEffect(() => {
        setShowForm(!Boolean(lastAnsweredAt));
    }, [lastAnsweredAt]);

    const submitFeedback = () => {
        postFeedback(authState.token, location, feedback);
        setShowForm(false);

        setLastAnsweredAt(new Date());
    };

    const getLastFeedbackTime = async (loc) => {
        const status = await getFeedbackStatus(authState.token, loc);
        if (status.last_feedback && status.last_feedback[0] && status.last_feedback[0].max) {
            setLastAnsweredAt(new Date(status.last_feedback[0].max));
        }
    };

    useEffect(() => {
        if (!authState.token) {
            return;
        }

        let path = window.location.pathname.replace(/\/+$/, '');
        path = path[0] == '/' ? path.substr(1) : path;
        const pathArray = path.split('/');

        const loc = pathArray[0] + '-' + pathArray[1] + "-" + pathArray[2];
        setLocation(loc);
        getLastFeedbackTime(loc);
    }, [authState]);

    return (
        <>
            {showForm && (
                <>
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        Feedback
                    </Typography>
                    <Typography paragraph>
                        Consider the Explained APIs Platform and using the slider, choose the options that best describe your opinion on each of the following statements. The options range from 1 (strongly disagree) to 7 (strongly agree).
                    </Typography>
                    <SliderQuestion
                        text="The Explained APIs Platform was useful for learning about the PokéAPI."
                        id="usefulForLearning"
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        text="The user interface of the Explained APIs Platform was user-friendly."
                        id="userInterfaceFriendly"
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        text="The Explained APIs Platform helped me in solving the PokéAPI -related course assignments."
                        id="helpedSolvingCourseAssignments"
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        text="The Explained APIs Platform helped me comprehend the PokéAPI."
                        id="helpedMeComprehendApi"
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        text="Platforms like the Explained APIs Platform can help with API comprehension."
                        id="canHelpWithApiComprehension"
                        handleChange={handleChange}
                    />
                    <SliderQuestion
                        text="I would prefer studying API documentations directly without the help of tools like the Explained APIs Platform."
                        id="wouldPreferStudyingApiDirectly"
                        handleChange={handleChange}
                    />
                    <Typography variant="h6" gutterBottom sx={{mt: 2}}>
                        How would you improve the Explained APIs Platform?
                    </Typography>
                    <TextField
                        onChange={handleChange('improvementSuggestions')}
                        name="improvementSuggestions"
                        multiline
                        rows={4}
                        aria-labelledby="improvementSuggestions"
                        defaultValue=""
                        variant="outlined"
                        fullWidth
                    />
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitFeedback}
                            sx={{ marginTop: '1em' }}
                        >
                            <Trans i18nKey="sendFeedback" />
                        </Button>
                    </React.Fragment>
                </>
            )}
            {lastAnsweredAt && !showForm && (
                <>
                    <Typography variant="body2" gutterBottom sx={{ mt: 1 }} fontStyle="italic">
                        <Trans
                            i18nKey="feedbackSentThanks"
                            values={{ lastAnsweredAt: lastAnsweredAt.toLocaleString() }}
                        />
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowForm(true)}
                        sx={{ mt: 1 }}
                    >
                        <Trans i18nKey="sendNewFeedback" />
                    </Button>
                </>
            )}
        </>
    );
};

const PokeAPIFeedbackForm = (props) => {
    return (
        <Paper sx={{ my: 2, p: 2 }}>
            <Feedback {...props} />
        </Paper>
    );
};

export default PokeAPIFeedbackForm;
