import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
    getAssignmentReviews,
} from '../../../http-actions/review-actions';
import { AuthContext } from '../../../providers/auth-provider';
import AdminReviewAssignment from './admin-review-assignment';

interface InputProps {
    assignmentUuid: string;
}

export default function ReviewAssignment(props: InputProps) {
    const [assignmentReviews, setAssignmentReviews] = useState([]);
    const submittedReviews = Array.isArray(assignmentReviews)
        ? assignmentReviews.filter((sr: any) => sr.review !== null)
        : [];

    const submittedPeerReviews = submittedReviews.filter((sr) => !sr.is_self_review);

    const { state: authState } = useContext(AuthContext);

    const fetchSubmittedReviews = async () => {
        const reviews = await getAssignmentReviews(authState.token, props.assignmentUuid)
        setAssignmentReviews(reviews);
    };

    useEffect(() => {
        if (authState.token) {
            void fetchSubmittedReviews();
        }
    }, [authState]);

    return (
        <>
            <Typography id="peer-review-header" variant="h2">
                {'Admin review'}
            </Typography>
            <AdminReviewAssignment
                assignmentUuid={props.assignmentUuid}
                fetchSubmittedReviews={fetchSubmittedReviews}
                nCompleted={submittedPeerReviews.length}
                download={'peer'}
            />
        </>
    );
}
