import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import {
    getPeerReviewFormByAssignmentUuid,
    getSubmissionForReviewZip,
    getAssignmentReviews,
    submitReview,
    getReviewAssignment,
} from '../../../http-actions/review-actions';
import { AuthContext } from '../../../providers/auth-provider';
import AssignmentHeader from '../../assignment/assignment-header';
import { getAssignmentActionButtonsGetter } from '../../assignment/programming/action-buttons';
import { assignmentTypes } from '../../assignment/programming/assignment-types';
import ProgrammingAssignment from '../../assignment/programming/programming-assignment';
import ProgrammingAssignmentPool from '../../assignment/programming/programming-assignment-pool';
import ResultInfo from '../../assignment/programming/result-info';
import SubmissionResultInfo from '../../assignment/programming/submission-result-info';
import Button from '../../button';
import PeerReviewForm from '../review-form';

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.fitech.contrastText,
            backgroundColor: theme.palette.fitech.main,
            transition: theme.transitions.create(['color', 'background-color']),
            '&:hover': {
                color: theme.palette.common.black,
            },
        },
    }),
);
*/

interface InputProps {
    assignmentUuid: string;
    getFormData: Function;
    fetchSubmittedReviews: Function;
    isFirstFetch: boolean;
    nCompleted: number;
    nAssignments: number;
    download?: boolean;
}

export default function PeerReviewAssignment(props: InputProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(null);
    const [resultInfoMessage, setResultInfoMessage] = useState(null);
    const [reviewAssignmentReviewId, setReviewAssignmentReviewId] = useState(null);
    const [showReview, setShowReview] = useState(false);
    console.log('\n\nreview assingment gg\n\n');
    console.log(reviewAssignmentReviewId);
    console.log(props);

    const { state: authState } = useContext(AuthContext);

    const fetchFormData = async (showReview: boolean) => {
        console.log('fetching form data');
        const newReviewFormData = await props.getFormData(authState.token, props.assignmentUuid);

        if (newReviewFormData.form) {
            setFormData(newReviewFormData.form);
            setReviewAssignmentReviewId(newReviewFormData.reviewAssignmentReviewId);
            setShowReview(showReview);
        } else {
            newReviewFormData.failureMessage &&
                setResultInfoMessage({
                    type: 'failure',
                    message: newReviewFormData.failureMessage,
                }); // TODO: localization
            newReviewFormData.err &&
                setResultInfoMessage({ type: 'error', message: newReviewFormData.err.toString() });
        }
    };

    const onSubmit = async (data: any) => {
        // translate
        const toSubmit = confirm(
            'Are you sure you want to submit? The review cannot be changed later.',
        );
        if (toSubmit) {
            void submitReview(authState.token, reviewAssignmentReviewId, data).then((response) => {
                if (!response.err) {
                    props.fetchSubmittedReviews();
                    setShowReview(false);
                    setResultInfoMessage({ type: 'success', message: 'reviewSubmitted' });
                } else {
                    setResultInfoMessage({
                        type: 'error',
                        message: 'unexpectedErrorOnSubmitReview',
                    });
                }
            });
        }
    };

    const triggerReviewFetch = async () => {
        const fetchReviewAssignment = props.isFirstFetch
            ? confirm('Proceeding will disable submitting updated versions of the project.')
            : true; // Tää confirm tulee nyt vaikka ois jo disablattu mutta eka submissio on null, pitäs ehkä tarkastaa myös assignatut-review:t eikä vaan palautettuja
        fetchReviewAssignment && (await fetchFormData(true));
    };

    // TODO FIX NAME FOR FILE TO HAVE THE CURRENT REVIEW "INDEX"
    const triggerCodeZipDownload = () => {
        console.log('DOWNLOAD');
        void getSubmissionForReviewZip(authState.token, reviewAssignmentReviewId).then((data) => {
            if (data.err) return;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `review-submission-${props.nCompleted + 1}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    // Separate programming assignment (project upload) from review assignment?
    return (
        <>
            {/* <ProgrammingAssignmentPool poolUuid="b6a36f21-964e-4ce0-b735-5801ba9e2ed8" /> */}
            {/* <ProgrammingAssignment show={true} assignment={assignment} points={points} maxPoints={maxPoints} /> */}
            <AssignmentHeader
                id={'review-assingment-id-todo'}
                nCompleted={props.nCompleted}
                nAssignments={props.nAssignments}
                assignmentsDoneText={t('reviewsSubmitted')}
            />
            {showReview ? (
                <PeerReviewForm
                    {...props}
                    onSubmit={onSubmit}
                    downloadCode={props.download && triggerCodeZipDownload}
                    downloadLink={''}
                    formData={formData}
                />
            ) : (
                props.nCompleted < props.nAssignments && (
                    <Button
                        onClick={triggerReviewFetch}
                        kind="fitech"
                        sx={{
                            mx: 1,
                            my: 2,
                            color: 'fitech.contrastText',
                            backgroundColor: 'fitech.main',
                            transition: (theme) =>
                                theme.transitions.create(['color', 'background-color']),
                            '&:hover': {
                                color: 'common.black',
                            },
                        }}
                        color="inherit"
                    >
                        {props.nCompleted === 0 ? 'Start review' : 'Start next review'}
                    </Button>
                )
            )}
            {resultInfoMessage && (
                <ResultInfo
                    messageKey={resultInfoMessage.message}
                    type={resultInfoMessage.type}
                    endIcon={<Close />}
                    onClick={() => setResultInfoMessage(null)}
                />
            )}
        </>
    );
}
