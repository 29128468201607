import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import SouthIcon from '@mui/icons-material/South';

interface InlineFeedbackHintPropsI {
    hintProbability?: number;
}

function InlineFeedbackHint({ hintProbability }: InlineFeedbackHintPropsI) {
    const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "unknown";

    if (pathname.includes("experiments") && pathname.includes("code-recall")) {
        return null;
    }

    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        let probabilityToShow = 1;
        if (
            typeof hintProbability !== 'undefined' &&
            typeof localStorage !== 'undefined' &&
            localStorage.getItem('inline-feedback-hint-seen') === 'seen'
        ) {
            probabilityToShow = hintProbability;
        }

        const show = Math.random() <= probabilityToShow;
        if (show) setShowHint(true);
    }, []);

    const visibilityStyle = {
        visibility: showHint ? 'visible' : 'hidden',
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '64px',
                height: '64px',

                top: { md: '-172px', xl: '-48px' },
                right: { md: '-64px', xl: '-100px' },
                ...visibilityStyle,
            }}
        >
            <SouthIcon
                fontSize="large"
                color="primary"
                sx={{
                    display: { xl: 'none' },
                    position: 'absolute',
                    top: '100px',
                    left: '24px',
                    fontSize: '3rem',
                }}
            />
            <SouthWestIcon
                color="primary"
                sx={{
                    display: { md: 'none', xl: 'block' },
                    position: 'absolute',
                    top: '72px',
                    fontSize: '3rem',
                }}
            />
            <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                    position: 'absolute',
                    left: { md: '16px', xl: '48px' },
                    fontSize: { md: '1rem', xl: 'inherit' },
                }}
            >
                Hover to provide feedback
            </Typography>
        </Box>
    );
}

export default InlineFeedbackHint;
